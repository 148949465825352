.gallery-carousel {
  .owl-carousel {
    .item {
      @extend .p-0;

      .gallery-img {
        border-radius: 8px;
      }
    }

    .owl-nav {
      margin: 0 0 12px 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 18px 0;
      }
    }
  }
}