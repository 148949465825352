@import '~framework/assets/scss/Dev/setup_css_vars';
@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import "node_modules/bootstrap/scss/variables";
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import "~framework/assets/scss/Dev/1_vendor/bootstrap";

body {
	//@import "~framework/assets/scss/Dev/base";

	@import '~framework/assets/scss/Dev/2_layout/base';
	@import '~framework/assets/scss/Dev/2_layout/containers';
	//@import '~framework/assets/scss/Dev/2_layout/header';
	//@import '~framework/assets/scss/Dev/2_layout/footer';
	//@import '~framework/assets/scss/Dev/2_layout/content';
	//@import '~framework/assets/scss/Dev/2_layout/mini-sab';
	@import '~framework/assets/scss/Dev/2_layout/eyecatcher';

	@import '~framework/assets/scss/Dev/3_components/alert';
	@import '~framework/assets/scss/Dev/3_components/buttons';
	@import '~framework/assets/scss/Dev/3_components/lists';
	@import '~framework/assets/scss/Dev/3_components/cards';
	@import '~framework/assets/scss/Dev/3_components/faq';
	@import '~framework/assets/scss/Dev/3_components/form';
	@import '~framework/assets/scss/Dev/3_components/reCAPTCHA';
	@import '~framework/assets/scss/Dev/3_components/loading';
	@import '~framework/assets/scss/Dev/3_components/owl-carousel';
	@import '~framework/assets/scss/Dev/3_components/bootstrap-carousel';
	@import '~framework/assets/scss/Dev/3_components/timeslot';

	@import '~framework/assets/scss/Dev/4_page_block/image';
	@import '~framework/assets/scss/Dev/4_page_block/gallery';
	@import '~framework/assets/scss/Dev/4_page_block/gallery-grid';
	@import '~framework/assets/scss/Dev/4_page_block/collections';
	@import '~framework/assets/scss/Dev/4_page_block/collections_custom';
	@import '~framework/assets/scss/Dev/4_page_block/contentblocks';
	@import '~framework/assets/scss/Dev/4_page_block/contentblocks_custom';

	@import '~framework/assets/scss/Dev/5_utilities/additions';
	@import '~framework/assets/scss/Dev/5_utilities/animations';

	//@import '~framework/assets/scss/Dev/6_theme/page.home';
	//@import '~framework/assets/scss/Dev/6_theme/page.contact';
	//@import '~framework/assets/scss/Dev/6_theme/page.error';
	//@import '~framework/assets/scss/Dev/6_theme/page.one_column';
	//@import '~framework/assets/scss/Dev/6_theme/page.two_columns';
	//@import '~framework/assets/scss/Dev/6_theme/page.three_columns';
	//@import '~framework/assets/scss/Dev/6_theme/page.test';
	@import '~framework/assets/scss/Dev/6_theme/module.accommodations';
	@import '~framework/assets/scss/Dev/6_theme/module.assortiment';
	@import '~framework/assets/scss/Dev/6_theme/module.blog';
	@import '~framework/assets/scss/Dev/6_theme/module.faq';
	@import '~framework/assets/scss/Dev/6_theme/module.service';
	@import '~framework/assets/scss/Dev/6_theme/module.timeslot';

	@import '~framework/assets/scss/Dev/7_hacks/shame';


	// app styling
	@import "./2_layout/base";
	@import "./2_layout/eyecatcher";
	@import "./2_layout/footer";
	@import "./2_layout/header";
	@import "./2_layout/menu";
	@import "./2_layout/mini-sab";
	@import "./2_layout/sections";

	@import "./3_components/buttons";
	@import "./3_components/cards";
	@import "./3_components/faq";
	@import "./3_components/lists";

	@import "./4_page_block/gallery";
	@import "./4_page_block/collections";

	@import "./5_utilities/animate";
	@import "./5_utilities/animista";
	@import "./5_utilities/contextual";

	@import "./6_theme/module.accommodations";
	@import "./6_theme/page.accommodation_book";
	@import "./6_theme/module.vacancy";
}
