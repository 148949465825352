.faq-overview {
	.faq-icon {
		@extend .position-absolute;
		top: $faq-icon-top;
		right: $faq-icon-right;
		bottom: $faq-icon-bottom;
		left: $faq-icon-top;
		z-index: 10;

		@extend .d-block;
		width: $faq-icon-width;

		font-size: $faq-icon-font_size;
		line-height: $faq-icon-line_height;
		@extend .text-center;
		color: $faq-icon-color;

		transition: $transition-base;
	}

  .faq-category {
    margin: $faq-category-margin;

    .faq-title {
			@extend .position-relative;
			margin: $faq-category-title-margin;
			padding: $faq-category-title-padding;
			
			background: $faq-category-title-background;
			cursor: pointer;
			
			@include media-breakpoint-up(sm) {
				padding: $faq-category-title-padding_sm;
			}
      
      h2 {
        margin: 0;

        font-family: $faq-category-title-font_family;
        font-size: $faq-category-title-font_size;
        font-style: $faq-category-title-font_style;
        font-weight: $faq-category-title-font_weight;
				line-height: $faq-category-title-line_height;
				text-transform: $faq-category-title-text_transform;
				text-decoration: $faq-category-title-text_decoration;
        color: $faq-category-title-color;

        @include media-breakpoint-up(sm) {
          font-size: $faq-category-title-font_size_sm;
          line-height: $faq-category-title-line_height_sm;
        }
			}
			
			.faq-icon {
				line-height: $faq-category-title-icon-line_height;
			}

      &:hover {
        .faq-icon {
					color: $faq-icon-color--hover;
				}
      }
    }

    .faq-items {
      display: none;

      .faq-item {
        .faq-question {
					@extend .position-relative;
					margin: $faq-question-margin;
					padding: $faq-question-padding;

					background: $faq-question-background;
					border: $faq-question-border;

					cursor: pointer;

					@include media-breakpoint-up(sm) {
						padding: $faq-question-padding_sm;
					}

          h3 {
						margin: 0;

						font-family: $faq-question-font_family;
						font-size: $faq-question-font_size;
						font-style: $faq-question-font_style;
						font-weight: $faq-question-font_weight;
						line-height: $faq-question-line_height;
						text-transform: $faq-question-text_transform;
						text-decoration: $faq-question-text_decoration;
						color: $faq-question-color;

						@include media-breakpoint-up(sm) {
							font-size: $faq-question-font_size_sm;
							line-height: $faq-question-line_height_sm;
						}
					}
      
          &:hover {
            .faq-icon {
							color: $faq-icon-color--hover;
						}
          }
				}
				
				.faq-answer {
					display: none;
					margin: $faq-answer-margin;
					padding: $faq-answer-padding;

					background: $faq-answer-background;
					border: $faq-answer-border;

					font-family: $faq-answer-font_family;
					font-size: $faq-answer-font_size;
					font-style: $faq-answer-font_style;
					font-weight: $faq-answer-font_weight;
					line-height: $faq-answer-line_height;
					text-transform: $faq-answer-text_transform;
					text-decoration: $faq-answer-text_decoration;
					color: $faq-answer-color;

					@include media-breakpoint-up(sm) {
						padding: $faq-answer-padding_sm;
					}
				}
      }
		}

	  &.js-single-category {
			.faq-items {
				display: block;
			}
  	}
  }
}