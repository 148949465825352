.gallery-grid {
  @include make-row();
  
  margin: 0 -15px -30px -15px;

  .gallery-item {
    @include make-col-ready();
    @include make-col(12);

    margin: 0 0 30px 0;

    transition: $transition-base;

    &:hover {
      transform: scale(1.025);
    }

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(md) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(3);
    }

    .gallery-link {
      position: relative;
      @extend .d-block;
    }

    .gallery-img {
      @extend .img-fluid;
      @extend .w-100;
    }

    .gallery-caption {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;

      @extend .align-items-center;
      @extend .justify-content-center;

      background: $gallery-caption-background;
      font-size: $gallery-caption-font_size;
      color: $gallery-caption-color;
    }
  }
}