&.vacancy_vacancy_overview {
	// mini-filter
	.mini-filter {
		margin: 4vh 0;
		input[type="submit"] {
			padding: 0.375rem 0.75rem;
			font-size: 16px;
			line-height: 1.5;
		}
	}
}

&.vacancy_vacancy_detail {
	.vacancy_detail {
		/* gallery */
		.gallery {
			display: none;
		}
	}
}
