// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 100vh !important; /* overide */
	min-height: 250px;
	max-height: 350px;
	@include media-breakpoint-down(xs) {
		height: auto !important; /* overide */
		min-height: auto;
		max-height: auto;
	}
}
.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: calc(85vh - 180px) !important; /* overide */
	min-height: 450px;
	max-height: 1080px;
	@include media-breakpoint-up(xl) {
		height: calc(100vh - 180px) !important; /* overide */
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		min-height: unset; /* overide */
		.item {
			padding: unset; /* overide */
			.owl-caption {
				display: none; /* hide */
				text-align: center;
			}
		}
		.owl-nav,
		.owl-dots {
			display: none;
		}
		.owl-item.active {
			@extend .scale-up-center;
		}
	}
}

.owl-carousel {
	.item {

		picture {
			width: 100%;
			height: 100%;
			img {
				height: 100%;
				object-fit: cover;
			}
		}
		.owl-caption-holder {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
