.btn { max-width: 100%; }

/* btn-white
   ========================================================================== */
.btn-white {
  background: $white;
  border: 1px solid $white;
  color: $body-color;

  &:hover {
    background: $primary;
    border: 1px solid $primary;
  }
}

/* btn-link
   ========================================================================== */
.btn-link {
  &--primary {
    color: $primary;

    &:hover {
      color: $secondary;
    }
  }

  &--white {
    color: $white;

    &:hover {
      color: $primary;
    }
  }
}

/* btn-back
   ========================================================================== */
.btn-back {
  @extend .mb-3;
  @extend .btn-link--primary;
  @extend .add-arrow--before;
  @extend .add-arrow--before-r;

  transition: $transition-base;
}