& {
  @include media-breakpoint-up(xl) {
    padding: $body-padding;
    
    transition: $transition-base;
  }

  &.sticky {
    @include media-breakpoint-up(xl) {
      padding: $body-sticky-padding;
    }
  }
}