.alert {
  margin: $alert-margin;
  padding: $alert-padding;
  border-radius: $alert-base-border_radius;
  box-shadow: $alert-box_shadow;

  font-family: $alert-font_family;
  font-size: $alert-font_size;
  font-style: $alert-font_style;
  font-weight: $alert-font_weight;
  line-height: $alert-line_height;

  .form-error-icon {
    @extend .d-none;
  }
}

/* alert > alert-danger
   ========================================================================== */
.alert-danger {
  background: $alert-danger-background;
  border: $alert-danger-border;
  color: $alert-danger-color;
}

/* alert > bg-dark
   ========================================================================== */
.bg-dark {
  .alert-danger {
    background: $alert-danger-background--dark;
    border: $alert-danger-border--dark;
    color: $alert-danger-color--dark;
  }
}