// header
.header {
	z-index: 999;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $primary;

	&.sticky {
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
	}

	.header-main {
		padding: 15px 0;
		background: transparent;

		/* override */
		@include media-breakpoint-up(xl) {
			padding: 15px;
		}

		.column {
			display: flex;
			align-items: center;

			&.two {
				justify-content: center;
			}

			&.three {
				flex-flow: row wrap;
				justify-content: flex-end;
			}

			@include media-breakpoint-down(md) {
				&.one {
					@include make-col-auto();
					order: 1;
				}

				&.two {
					@include make-col-auto();
					order: 2;
				}

				&.three {
					@include make-col(12);
					order: 0;
				}
			}
		}

		// navbar-toggler
		.navbar-toggler {
			padding: 0 12px;
			background: $secondary;
			border-radius: 5px;
			border: none;
			color: $white;
			font-size: 16px;
			font-weight: 700;
			line-height: 45px;
			transition: 0.5s;

			@include media-breakpoint-up(xl) {
				min-width: 130px;
				font-size: 18px;
				line-height: 54px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}

			.navbar-toggler-icon {
				width: 21px;
				height: auto;
				font-size: 20px;
				line-height: 20px;
			}

			.navbar-toggler-label {
				margin-left: 5px;

				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			&:hover {
				background: darken($secondary, 15%);
			}

			&:focus {
				background: $secondary;
			}
		}

		// book-button
		.book-button {
			@include media-breakpoint-down(xs) {
				display: none !important;
			}

			margin: 0 0 0 15px;

			@include media-breakpoint-up(xl) {
				margin-left: 30px;
			}

			li {
				margin-right: 0;

				a {
					display: block;
					padding: 0 15px;
					background: $white;
					border-radius: 5px;
					border: none;
					color: $red;
					font-size: 16px;
					font-weight: 700;
					line-height: 45px;
					text-align: center;
					text-decoration: none;

					@include media-breakpoint-up(xl) {
						min-width: 180px;
						font-size: 18px;
						line-height: 54px;
					}

					&:hover {
						background: $red;
						color: $white;
					}
				}
			}
		}

		// park-logo
		.park-logo {
			display: flex;
			text-decoration: none;
			@extend .text-focus-in;

			.image {
				width: 45px;
				height: 45px;

				@include media-breakpoint-up(xl) {
					width: 80px;
					height: 80px;
				}
			}

			.text {
				margin-left: 10px;
				color: $white;
				line-height: 1;
				font-weight: 700;
				text-transform: uppercase;
				font-family: $font-family-secondary;

				.name {
					@include media-breakpoint-down(xs) {
						font-size: 25px;
					}

					@include font-size($h1-font-size);

					@include media-breakpoint-up(xl) {
						font-size: 52px;
					}
				}

				.subname {
					@include media-breakpoint-down(xs) {
						font-size: 10px;
					}

					@include font-size($h5-font-size);

					@include media-breakpoint-up(xl) {
						font-size: 24px;
					}
				}
			}
		}

		// top-menu
		.top-menu {
			display: block;

			@include media-breakpoint-down(xs) {
				flex-grow: 0;
				margin-left: 15px;
				line-height: 1;
			}

			@include media-breakpoint-down(md) {
				flex-basis: unset;
			}

			.navbar-nav {
				margin: 0;
				flex-flow: row wrap;
				justify-content: flex-end;

				.nav-item {
					font-size: 14px;

					+li {
						margin-left: 15px;
					}

					@include media-breakpoint-down(xs) {
						&:nth-last-child(-n + 2) {
							display: none;
						}
					}

					.nav-link {
						display: inline-flex;
						align-items: center;
						padding: 0;
						color: $white;

						.menu-icon {
							i {
								font-size: 18px;

								@include media-breakpoint-only(sm) {
									font-size: 20px;
								}

								@include media-breakpoint-only(xs) {
									font-size: 22px;
								}
							}
						}

						.menu-title {
							margin-left: 5px;

							@include media-breakpoint-down(sm) {
								display: none;
							}
						}

						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}

		// channels
		.channels {
			margin-left: 15px;
			font-size: 14px;

			@include media-breakpoint-down(xs) {
				order: -1;
				line-height: 1;
			}

			.nav-link {
				display: flex;
				align-items: center;
				padding: 0;
				color: $white;
				font-weight: 700;

				&::after {
					content: "\f107";
					font-family: $font-awesome;
					font-size: 18px;

					@include media-breakpoint-down(md) {
						font-size: 14px;
					}

					font-weight: 900;
					float: right;
					margin-left: 5px;
					border: none;
					line-height: 1;
				}

				&:hover {
					color: $secondary;
				}

				.fa-map-marker-alt {
					font-size: 18px;

					@include media-breakpoint-down(sm) {
						font-size: 22px;
					}
				}
			}

			.dropdown-menu {
				border-radius: 14px;
				background: $primary;
				box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);

				.dropdown-item {
					color: $white;
					font-size: 14px;
					font-weight: 700;
					padding: 0.25rem 0.5rem;

					&:hover,
					&:focus {
						background: $secondary;
						color: $white;
					}

					&:last-of-type {
						display: none;
					}
				}
			}
		}

		// languages
		.languages {
			min-width: 76px;
			margin-left: 25px;

			.navbar-nav {
				flex-direction: row;

				.nav-item {
					+li {
						margin-left: 5px;
					}

					.nav-link {
						display: block;
						padding: 0;
						border-radius: 100%;
						border: 1px solid $white;

						img {
							display: block;
							width: 20px;
							height: 20px;
							border-radius: 100%;
						}

						&:hover:not(.active) {
							border-color: $secondary;
							box-shadow: 0 0 6px rgba($black, 0.3);
						}
					}
				}
			}
		}
	}
}
