.container {
	padding: $container-padding;
	@extend .px-sm-0;
}

.container-small {
	width: 100%;
	margin: 0 auto;
	padding: $container-padding;

	@include media-breakpoint-up(sm) {
		max-width: $container-small-max_width_sm;
		padding: $container-padding_sm;
	}

	@include media-breakpoint-up(md) {
		max-width: $container-small-max_width_md;
	}

	@include media-breakpoint-up(lg) {
		max-width: $container-small-max_width_lg;
	}

	@include media-breakpoint-up(xl) {
		max-width: $container-small-max_width_xl;
	}
}

.container-fluid {
	max-width: $container-fluid-max_width;
	padding: $container-padding;

	@include media-breakpoint-up(sm) {
		padding: $container-padding_sm;
	}
}

/* Container-navbar
   ========================================================================== */
.container-navbar {
	.container-holder {}

	.column.default {}

	.column.navbar {
		@extend .flex-wrap;
	}
}


/* Container-default
   ========================================================================== */
.container-default {
	.container-holder {}
}


/* Container-one-column
   ========================================================================== */
.container-one-column {
	.container-holder {
		@include make_row();
	}

	.column.default {
		@include make-col-ready();
		@include make-col(12);
	}
}


/* Container-two-columns
   ========================================================================== */
.container-two-columns {
	.container-holder {
		@include make-row();

		margin: 0 -15px -15px -15px;
	}

	.column.one,
	.column.two {
		@include make-col-ready();
		@include make-col(12);

		margin: 0 0 15px 0;

		@include media-breakpoint-up(md) {
			@include make-col(6);
		}
	}
	.column.one {
		@extend .pr-3;
		@extend .pr-md-4;
		@extend .pr-lg-5;
	}
	.column.two {
		@extend .pl-3;
		@extend .pl-md-4;
		@extend .pl-lg-5;
	}
}


/* Container-three-columns
   ========================================================================== */
.container-three-columns {
	.container-holder {
		@include make-row();

		margin: 0 -15px -15px -15px;
	}

	.column.one,
	.column.two,
	.column.three {
		@include make-col-ready();
		@include make-col(12);

		margin: 0 0 15px 0;

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}
	}
}


/* Container-four-columns
   ========================================================================== */
.container-four-columns {
	.container-holder {
		@include make-row();

		margin: 0 -15px -15px -15px;
	}

	.column.one,
	.column.two,
	.column.three,
	.column.four {
		@include make-col-ready();
		@include make-col(12);

		margin: 0 0 15px 0;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}
		
		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}
	}
}


/* Container-six-columns
   ========================================================================== */
.container-six-columns {
	.container-holder {
		@include make-row();

		margin: 0 -15px -15px -15px;
	}

	.column.one,
	.column.two,
	.column.three,
	.column.four,
	.column.five,
	.column.six {
		@include make-col-ready();
		@include make-col(12);

		margin: 0 0 15px 0;

		@include media-breakpoint-up(sm) {
			@include make-col(6);
		}

		@include media-breakpoint-up(md) {
			@include make-col(4);
		}

		@include media-breakpoint-up(xl) {
			@include make-col(2);
		}
	}
}