.eyecatcher {
	min-height: $carousel-min_height;
	@extend .loading-overlay;

	.eyecatcher-container {
		@extend .px-0;
	}

	&.large {
		min-height: $carousel-min_height_lg;
	}
}