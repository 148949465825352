// mini-sab
.mini-sab {
	background: $primary;

	.container {
		position: relative;
		display: flex;
		justify-content: center;

		.container-holder {
			z-index: 100;
			position: relative;
			padding: 20px 30px;
			background: $yellow;
			box-shadow: 5px 6px 0 0 $quaternary;
			border-radius: 25px;

			@include media-breakpoint-up(lg) {
				border-radius: 30px;
				box-shadow: 10px 10px 0 0 $quaternary;
				padding: 25px 30px 20px 45px;
			}

			.heading {
				@include font-size($h1-font-size);
				margin: -10px 0 10px 0;
				color: $white;
				font-weight: 500;
				text-align: center;
			}
		}
	}
}

// home
&.home {
	.mini-sab {
		padding-bottom: 6vh;

		.container {
			.container-holder {
				margin-top: -50px;

				@include media-breakpoint-up(lg) {
					margin-top: -100px;
				}
			}
		}
	}
}

// :not home
&:not(.home) {
	.mini-sab {
		margin-top: calc(6vh + 25px);
		margin-bottom: calc(6vh + 25px);
		.container {
			.container-holder {
				margin-top: -25px;
			}
		}
	}
}
