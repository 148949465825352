$font-awesome: "Font Awesome 5 Pro" !default;

/* bootstrap > shades
   ========================================================================== */
$white:    #FFFFFF !default;
$gray-100: #F8F9FA !default;
$gray-200: #E9ECEF !default;
$gray-300: #DEE2E6 !default;
$gray-400: #CED4DA !default;
$gray-500: #ADB5BD !default;
$gray-600: #6C757D !default;
$gray-700: #495057 !default;
$gray-800: #343A40 !default;
$gray-900: #212529 !default;
$black:    #000000 !default;

/* bootstrap > colors
   ========================================================================== */
$blue:     #007BFF !default;
$indigo:   #6610F2 !default;
$purple:   #6F42C1 !default;
$pink:     #E83E8C !default;
$red:      #DC3545 !default;
$orange:   #FD7E14 !default;
$yellow:   #FFC107 !default;
$green:    #BBD331 !default;
$teal:     #20C997 !default;
$cyan:     #17A2B8 !default;

/* bootstrap > theme-colors
   ========================================================================== */
$primary:       $green !default;
$secondary:     $gray-600 !default;
$tertiary:		  $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: (
	"tertiary":	$tertiary,
);

/* bootstrap > body
   ========================================================================== */
$body-bg:                   $white !default;
$body-color:                $black !default;

$link-color:                              $primary !default;
$link-color--hover:                        darken($link-color, 15%) !default;

/* bootstrap > font
	========================================================================== */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

$font-family-open-sans: 'Open Sans', sans-serif !default;
$font-family-base: $font-family-open-sans !default;

/* bootstrap > typography
   ========================================================================== */
$font-weight-medium:          500 !default;
$font-weight-semi-bold:       600 !default;
$font-weight-bold:            700 !default;

$font-style-italic:           italic !default;
$font-style-base:             normal !default;

$headings-font-family:        $font-family-base !default;
$headings-font-style:         $font-style-base !default;
$headings-font-weight:        $font-weight-bold !default;
$headings-color:              $body-color !default;
