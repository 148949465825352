/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 0;
   $body-sticky-padding: 0;


/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;


/* ==========================================================================
    card
    ========================================================================== */
    $card-background: $white;
    $card-border: none;
    $card-base-border-radius: 6px;
    $card-box_shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

/*  card > card-body
    ========================================================================== */
    $card-body-padding: 25px 20px;
    $card-body-background: $white;

/*  card > card-caption
    ========================================================================== */

    /* card > card-title */
    $card-title-font_size: $font-size-base * 1.25;
    $card-title-text_transform: uppercase;
    $card-title-color: $primary;

    /* card > card-subtitle */
    $card-subtitle-color: $black;
    $card-subtitle-text_transform: uppercase;

    /* card > card-description */
    $card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);


/* ==========================================================================
    owl-carousel
    ========================================================================== */
    $carousel-nav-button-width: auto;
    $carousel-nav-button-height: auto;
    $carousel-nav-button-font_size: 36px;
    $carousel-nav-button-line_height: 1;
    $carousel-nav-button-text_shadow: none;
    $carousel-nav-button-color: $white;
    $carousel-nav-button-color--hover: $white;


/* ==========================================================================
   timeslot
   ========================================================================== */
/* timeslot > category > title
   ========================================================================== */
   $timeslot-category-title-color: $primary;

/* timeslot > datepicker > calendar
   ========================================================================== */
   /* timeslot > datepicker > calendar > item */
   $timeslot-datepicker-calendar-item-color--active: $white;

/* timeslot > item > title
   ========================================================================== */
   $timeslot-item-title-color: $primary;

/* timeslot > item > day > title
   ========================================================================== */
   $timeslot-item-day-title-background: $primary;

   $timeslot-item-day-title-color: $white;

/* timeslot > item > day > times
   ========================================================================== */
   $timeslot-item-day-times-background: $secondary;

   $timeslot-item-day-times-color: $white;
