/*  default
    ========================================================================== */
.card {
	text-decoration: none;
	.card-image {
		overflow: hidden;
		.card-img {
			display: block;
			transition: transform 0.5s;
		}
		&:hover {
			.card-img {
				transform: scale(1.05);
			}
		}
	}
	.card-body {
		.card-caption {
			.card-title-link {
				margin-bottom: 0;
			}
			.card-description {
				.list {
					.list-item {
						.icon {
							color: $secondary;
						}
					}
				}
				@include media-breakpoint-up(sm) {
					min-height: 0;
					max-height: none;
				}

				&::after {
					@extend .d-none;
				}
			}
		}
		.card-buttons {
			.card-btn {
				@extend .btn-primary;
				@include media-breakpoint-up(xl) {
					padding: 0.85rem 1.5rem;
				}
			}
		}
	}
}
