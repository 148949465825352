// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-promotion-section
// =========================================================================
.lead-promotion-section {
	background: $primary;
	padding-bottom: calc(6vh + 45px);

	.container {
		@include media-breakpoint-up(xl) {
			max-width: 1140px;
		}
	}

	.container-holder {
		padding: 25px 15px 1px 15px;
		background: $secondary;
		border-radius: 25px;
		color: $white;
		font-size: 18px;

		@include media-breakpoint-up(xl) {
			font-size: 24px;
		}

		font-weight: 700;
		text-align: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			font-weight: 900;
			text-shadow: 4px 4px 0 $quaternary;
		}

		h1 {
			margin: -20px 0 0 0;
			line-height: 1;
			font-size: 40px;

			@include media-breakpoint-between(md, lg) {
				margin-top: -25px;
				font-size: 50px;
			}

			@include media-breakpoint-up(xl) {
				margin-top: -45px;
				text-shadow: 8px 8px 0 $quaternary;
				font-size: 70px;
			}

			animation: fadeInRight 1s 0.5s;
		}

		h2,
		h3 {
			@include font-size($h1-font-size);
			animation: fadeInLeft 1s 0.5s;
		}

		ul.custom-list {
			margin: 1.5rem 0;

			li {
				padding: 0;

				&::before {
					position: static;
					margin-right: 15px;
					color: $quaternary;
					font-size: larger;
				}
			}
		}

		.link {
			@extend .btn;
			@extend .btn-primary;
			font-size: 18px;
			margin-bottom: -30px;
			padding: 20px 1.5rem !important;

			@include media-breakpoint-up(xl) {
				display: block;
				max-width: 440px;
				margin: 0 auto -40px auto;
				padding: 25px 1.5rem !important;
				font-size: 22px !important;
				font-weight: 900 !important;
			}
		}
	}
}

// usps-section
// =========================================================================
.usps-section {
	@include media-breakpoint-down(sm) {
		display: none;
	}

	@extend .bg-dark;
	@extend .pt-0;
	background: url("/images/garland-lights.svg") repeat-x;
	background-position: left center;
	background-position-x: -100vw;
	animation: movingBg 120s linear infinite;

	@keyframes movingBg {
		50% {
			background-position-x: 0vw;
		}
	}

	.park-usp {
		ul {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 180px;
				height: 180px;
				margin: 15px;
				padding: 5px;
				background: $primary;
				border-radius: 100%;
				@include font-size($h4-font-size);
				font-weight: 700;
				line-height: 1;
				text-align: center;
				text-shadow: 4px 4px 0 $quaternary;

				&:nth-child(1) {
					transform: rotate(20deg);
				}

				&:nth-child(2) {
					transform: rotate(-15deg);
				}

				&:nth-child(3) {
					transform: rotate(20deg);
				}

				&::before,
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 180px;
					height: 180px;
					border-radius: 100%;
					border: 3px dotted $white;
					animation: rotating 15s linear infinite reverse;

				}

				&::before {
					top: 7px;
					left: 7px;
					border-color: $secondary;
				}

				@keyframes rotating {
					from {
						transform: rotate(0deg);
					}

					to {
						transform: rotate(360deg);
					}
				}

				strong {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 180px;
					height: 180px;
					font-weight: 700;

					&::before,
					&::after {
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						width: 74px;
						margin: 0 auto;
						transform: rotate(20deg);
					}

					&::before {
						top: 15px;
						height: 35px;
						background: url("/images/stars-top.svg");
					}

					&::after {
						bottom: 10px;
						height: 37px;
						background: url("/images/stars-bottom.svg");
					}
				}
			}
		}
	}
}

// intro-section
// =========================================================================
.intro-section {
	@extend .bg-dark;
	@extend .pt-0;
	font-weight: 500;
	text-align: center;

	h1 {
		margin-bottom: 4.5vh;
		font-weight: 500;
	}

	h2 {
		@include font-size($h1-font-size);
		line-height: 1;
		font-weight: 900;
		text-shadow: 5px 5px 0 $quaternary;

		@include media-breakpoint-up(xl) {
			font-size: 60px;
		}

		//animation: text-shadow 0.6s 1s both;
	}

	.container-two-columns {
		max-width: 960px;

		p {
			max-width: 320px;
			margin: 1rem auto;
		}
	}
}

// content-section
// =========================================================================
.content {
	margin: 6vh 0;
}

// park-overview-section
// =========================================================================
.park-overview-section {
	@extend .fullpage-section;
	padding: 6vh 0;
	background-color: $secondary;

	.contentblock {
		@extend .custom-contentblock;

		&.grid {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.item {
				@include make-col(12);
				margin-top: 6vh;
				margin-bottom: 6vh;
				background: url("/images/bg-visual-tents.svg") repeat-x center center $secondary;

				.card {
					@extend .container;

					.card-body {
						.card-caption {

							.card-title,
							.card-subtitle {
								text-shadow: 4px 4px 0 $quaternary;

								@include media-breakpoint-up(xl) {
									text-shadow: 8px 8px 0 $quaternary;
								}

								@include media-breakpoint-down(xs) {
									word-break: break-all;
								}
							}

							@include media-breakpoint-up(md) {
								.card-description {
									margin-right: 30px;
								}
							}
						}
					}
				}

				&:nth-child(2n) {
					.card {
						@include media-breakpoint-up(md) {
							flex-direction: row !important;

							.card-description {
								margin-left: 30px;
								margin-right: 0;
							}
						}
					}
				}
			}
		}

		// animate
		.owl-item.active .item .card .card-image {
			animation: fadeInRight 1s;
		}

		.owl-item.active .item .card .card-body {
			animation: fadeInLeft 1s;
		}
	}
}

// parks-overview-section
// =========================================================================
.parks-overview-section {
	@extend .fullpage-section;
	padding: 8vh 0 30px 0;
	background: url("/images/bg-visual-tents.svg") repeat-x 0 50px $secondary;

	h1,
	h2 {
		@include font-size($h1-font-size);
		margin-bottom: 6vh;
		color: $white;
		font-weight: 500;
		text-align: center;
	}

	.contentblock {
		@extend .custom-contentblock;

		.card {
			.card-body {
				.card-caption {

					.card-subtitle,
					.card-title {
						text-shadow: 4px 4px 0 $quaternary;

						@include media-breakpoint-up(xl) {
							text-shadow: 8px 8px 0 $quaternary;
						}
					}
				}
			}
		}

		// owl-nav
		.owl-nav {
			@extend .custom-owl-nav;
		}

		// owl-dots
		.owl-dots {
			@extend .custom-owl-dots;
		}

		// animate
		.owl-item.active .item .card .card-image {
			animation: fadeInRight 1s;
		}

		.owl-item.active .item .card .card-body {
			animation: fadeInLeft 1s;
		}
	}
}

// spacer
// =========================================================================
.spacer {
	@extend .fullpage-section;
	padding: 130px 0 8vh 0;
	background: url("/images/garland-flags.svg") repeat-x $secondary;
	background-position-x: -100vw;
	animation: movingBg 120s linear infinite;

	@keyframes movingBg {
		50% {
			background-position-x: 0vw;
		}
	}

	.owl-carousel {
		min-height: 60vh;

		.owl-stage-outer {
			padding-bottom: 180px;
		}

		.item {
			min-height: 60vh;
			padding: 0;
			align-items: flex-end;

			.owl-caption-holder {
				top: auto;
			}

			.owl-caption {
				margin-bottom: -180px;
				text-align: center;
				word-wrap: break-word;

				.owl-title {
					margin-bottom: 0;
					@include font-size($h1-font-size);
					line-height: 1;
					font-weight: 900;
					text-shadow: 4px 4px 0 $quaternary;
					text-transform: uppercase;

					@include media-breakpoint-up(xl) {
						margin-top: -45px;
						font-size: 100px;
						text-shadow: 8px 8px 0 $quaternary;
					}
				}

				.owl-subtitle {
					margin: 15px 0;
				}

				.owl-description {
					margin: 30px 0;
					text-shadow: none;
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;

					ul {
						@extend .custom-list;
						display: flex;
						flex-flow: row wrap;
						justify-content: center;

						li {
							margin: 0 15px;
							padding-left: 0 !important;

							&::before {
								position: static !important;
								margin-right: 10px;
								font-size: larger;
								line-height: 1;
							}
						}
					}
				}

				.owl-btn {
					margin-top: 5px;
					box-shadow: none !important;
				}
			}
		}

		// animate
		.owl-item.active .item .owl-caption {
			animation: fadeInUp 1s;
		}
	}
}

// parc-deals-section
// =========================================================================
.parc-deals-section {
	@extend .fullpage-section;
	padding: 10vh 0;
	background: url("/images/bg-visual-campers.svg") repeat-x 0 30px $primary;
	/*
	background-position-x: 100vw;
	animation: movingBg 120s linear infinite;
    @keyframes movingBg {
      50% {
        background-position-x: 0vw;
      }
    }
	*/

	.contentblock {
		.item {
			.card {
				background: transparent;
				border-radius: 0;
				box-shadow: none;

				.card-body {
					margin-top: 30px;
					padding: 0;
					background: transparent;

					.card-caption {
						color: $white;

						.card-subtitle {
							color: inherit;
							font-size: 18px;
							font-weight: 400;
							text-transform: none;
						}

						.card-title {
							margin-bottom: 20px;
							color: inherit;
							font-weight: 900;
							text-transform: none;
							@include font-size($h3-font-size);
						}

						.card-description {
							color: inherit;

							@include media-breakpoint-up(xl) {
								font-size: 18px;
							}
						}
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			@extend .custom-owl-dots;

			.owl-dot {

				&:focus,
				&:hover,
				&.active {
					span {
						background: $secondary;
						border: none;

						&::after {
							border-color: $secondary;
						}
					}
				}
			}
		}
	}
}

//  latest-news-section
// =========================================================================
.latest-news-section {
	@extend .fullpage-section;
	padding: 160px 0 8vh 0;
	background: url("/images/garland-lights.svg") repeat-x $primary;
	background-position-x: -100vw;
	animation: movingBg 120s linear infinite;

	@keyframes movingBg {
		50% {
			background-position-x: 0vw;
		}
	}

	.h2 {
		margin-bottom: 4.5vh;
		color: $white;
		font-weight: 400;
		text-align: center;

		@include media-breakpoint-up(xl) {
			font-size: 40px;
		}
	}

	.contentblock {
		@extend .custom-contentblock;

		// owl-nav
		.owl-nav {
			@extend .custom-owl-nav;
		}

		// owl-dots
		.owl-dots {
			@extend .custom-owl-dots;

			.owl-dot {

				&:focus,
				&:hover,
				&.active {
					span {
						background: $secondary;
						border: none;

						&::after {
							border-color: $secondary;
						}
					}
				}
			}
		}

		// animate
		.owl-item.active .item .card .card-image {
			animation: fadeInRight 1s;
		}

		.owl-item.active .item .card .card-body {
			animation: fadeInLeft 1s;
		}
	}
}
