.owl-carousel {
  min-height: $carousel-min_height;

  &:not(.slider) {
    @extend .loading-overlay;
  }

  .owl-stage {
    @extend .d-flex;

    .owl-item {
      flex: $carousel-flex;
    }
  }

  .item {
    @extend .d-flex;
    align-items: $carousel-align_items;

    min-height: $carousel-min_height;
    @extend .h-100;
    padding: $carousel-item_padding;

    background-position: $carousel-item-background_position;
    background-repeat: $carousel-item-background_repeat;
    background-size: $carousel-item-background_size;


    .owl-caption-holder {
      width: 100%;
    }

    .owl-container {
      @extend .container;
    }

    .owl-caption {
      padding: $carousel-caption-padding;
      background: $carousel-caption-background;

      text-align: $carousel-caption-text_align;
    }

    .owl-title {
      margin: $carousel-title-margin;

      font-family: $carousel-title-font_family;
      font-size: $carousel-title-font_size;
      font-style: $carousel-title-font_style;
      font-weight: $carousel-title-font_weight;
      line-height: $carousel-title-line_height;
      text-transform: $carousel-title-text_transform;
      text-shadow: $carousel-title-text_shadow;
      color: $carousel-title-color;

      @include media-breakpoint-up(sm) {
        font-size: $carousel-title-font_size_sm;
        line-height: $carousel-title-line_height_sm;
      }
    }

    .owl-subtitle {
      margin: $carousel-subtitle-margin;

      font-family: $carousel-subtitle-font_family;
      font-size: $carousel-subtitle-font_size;
      font-style: $carousel-subtitle-font_style;
      font-weight: $carousel-subtitle-font_weight;
      line-height: $carousel-subtitle-line_height;
      text-transform: $carousel-subtitle-text_transform;
      text-shadow: $carousel-subtitle-text_shadow;
      color: $carousel-subtitle-color;

      @include media-breakpoint-up(sm) {
        font-size: $carousel-subtitle-font_size_sm;
        line-height: $carousel-subtitle-line_height_sm;
      }
    }

    .owl-description {
      margin: $carousel-description-margin;

      font-family: $carousel-description-font_family;
      font-size: $carousel-description-font_size;
      font-style: $carousel-description-font_style;
      font-weight: $carousel-description-font_weight;
      line-height: $carousel-description-line_height;
      text-transform: $carousel-description-text_transform;
      text-shadow: $carousel-description-text_shadow;
      color: $carousel-description-color;

      .list {
        .list-item {
          color: $carousel-description-color;
        }
      }
    }

    .owl-btn {
      @extend .btn;
      @extend .btn-primary;
      @extend .shadow;

      &:hover {
        @extend .btn-secondary;
      }
    }
  }

  .owl-nav {
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    z-index: 100;

    margin: $carousel-nav-margin;

    .owl-prev,
    .owl-next {
      position: absolute;

      display: block;
      width: $carousel-nav-button-width;
      height: $carousel-nav-button-height;
      outline: 0;

      font-size: $carousel-nav-button-font_size;
      line-height: $carousel-nav-button-line_height;
      @extend .text-center;
      text-shadow: $carousel-nav-button-text_shadow;
      color: $carousel-nav-button-color;

      transition: $transition-base;

      &:hover {
        color: $carousel-nav-button-color--hover;
      }

      &.disabled {
        //display: none;
        opacity: .25;
        pointer-events: none;
      }
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }

    &.disabled {
      @extend .d-none;
    }
  }

  .owl-dots {
    position: absolute;
    right: 0;
    bottom: 30px;
    left: 0;
    z-index: 100;

    height: 0;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .owl-dot {
      @extend .d-block;
      outline: 0;

      span {
        @extend .d-block;
        width: $carousel-dots-button-width;
        height: $carousel-dots-button-height;
        margin: $carousel-dots-button-margin;

        background: $carousel-dots-button-background;
        border: $carousel-dots-button-border;
        border-radius: $carousel-dots-button-border_radius;
        box-shadow: $carousel-dots-button-box_shadow;

        transition: $transition-base;
      }

      &:hover {
        span {
          background: $carousel-dots-button-background--hover;
          border: $carousel-dots-button-border--hover;
        }
      }
      &.active {
        span {
          background: $carousel-dots-button-background--active;
          border: $carousel-dots-button-border--active;
        }
      }
    }

    &.disabled {
      @extend .d-none;
    }
  }
}

/* owl-carousel > large
   ========================================================================== */
.owl-carousel.large {
  min-height: $carousel-min_height_lg;

  .item {
    min-height: $carousel-min_height_lg;
    padding: $carousel-item_padding_lg;
  }
}


/* owl-carousel > slider
   ========================================================================== */
.owl-carousel {
  &.slider {
    .item {
      @extend .align-items-start;

      min-height: 0;
      padding: 0;
    }

    .owl-nav {
      right: -30px;
      left: -30px;

      .owl-prev,
      .owl-next {
        text-shadow: $slider-nav-button-text_shadow;
        color: $slider-nav-button-color;

        &:hover {
          color: $slider-nav-button-color--hover;
        }
      }
    }

    .owl-dots {
      @extend .position-relative;
      bottom: 0;

      height: auto;
      margin-top: 15px;

      .owl-dot {
        span {
          background: $slider-dots-button-background;
          border: $slider-dots-button-border;
          box-shadow: $slider-dots-button-box_shadow;
        }

        &:hover {
          span {
            background: $slider-dots-button-background--hover;
            border: $slider-dots-button-border--hover;
          }
        }
        &.active {
          span {
            background: $slider-dots-button-background--active;
            border: $slider-dots-button-border--active;
          }
        }
      }
    }
  }
}

/* owl-carousel > .bg-dark > slider
   ========================================================================== */
.bg-dark {
  .owl-carousel {
    &.slider {
      .owl-nav {
        .owl-prev,
        .owl-next {
          color: $slider-nav-button-color--dark;

          &:hover {
            color: $slider-nav-button-color--dark--hover;
          }
        }
      }

      .owl-dots {
        .owl-dot {
          span {
            background: $slider-dots-button-background--dark;
            border: $slider-dots-button-border--dark;
          }

          &:hover {
            span {
              background: $slider-dots-button-background--dark--hover;
              border: $slider-dots-button-border--dark--hover;
            }
          }
          &.active {
            span {
              background: $slider-dots-button-background--dark--active;
              border: $slider-dots-button-border--dark--active;
            }
          }
        }
      }
    }
  }
}
