.card {
  @extend .h-100;
  background: $card-background;
  border: $card-border;
  border-radius: $card-base-border_radius;
  box-shadow: $card-box_shadow;
  overflow: hidden;

  .card-image {
    @extend .position-relative;

    flex: $card-image-flex;
    background: $card-image-background;

    .card-image-link {
      @extend .d-block;
    }

    .card-img {
      border-radius: $card-image-border_radius;
    }

    .card-image-caption {
      @extend .position-absolute;
      top: $card-image-caption-top;
      right: $card-image-caption-right;
      bottom: $card-image-caption-bottom;
      left: $card-image-caption-left;

      display: $card-image-caption-display;
      align-items: $card-image-caption-align_items;
      justify-content: $card-image-caption-justify_content;
      padding: $card-image-caption-padding;
      background: $card-image-caption-background;

      .card-image-label {
        padding: $card-image-label-padding;
        background: $card-image-label-background;
        @extend .text-truncate;

        font-family: $card-image-label-font_family;
        font-size: $card-image-label-font_size;
        font-style: $card-image-label-font_style;
        font-weight: $card-image-label-font_weight;
        line-height: $card-image-label-line_height;
        text-shadow: $card-image-label-text_shadow;
        text-align: $card-image-label-text_align;
        color: $card-image-label-color;
      }
    }
  }

  .card-body {
    @extend .d-flex;
    @extend .flex-column;
    padding: $card-body-padding;
    background: $card-body-background;
  }

  .card-caption {
    flex: $card-caption-flex;
    text-align: $card-caption-text_align;

    .card-title {
      margin: $card-title-margin;

      font-family: $card-title-font_family;
      font-size: $card-title-font_size;
      font-style: $card-title-font_style;
      font-weight: $card-title-font_weight;
      line-height: $card-title-line_height;
      text-transform: $card-title-text_transform;
      text-shadow: $card-title-text_shadow;
      text-align: $card-title-text_align;
      color: $card-title-color;

      transition: $transition-base;

      @include media-breakpoint-up(sm) {
        font-size: $card-title-font_size_sm;
        line-height: $card-title-line_height_sm;
      }
    }

    .card-title-link {
      @extend .d-block;
      margin: $card-title-margin;
      text-decoration: $card-title-link-text_decoration;
      color: $card-title-link-color;

      .card-title {
        margin: 0;
        color: inherit;
      }

      &:hover {
        text-decoration: $card-title-link-text_decoration--hover;
        color: $card-title-link-color--hover;
      }
    }

    .card-subtitle {
      margin: $card-subtitle-margin;

      font-family: $card-subtitle-font_family;
      font-size: $card-subtitle-font_size;
      font-style: $card-subtitle-font_style;
      font-weight: $card-subtitle-font_weight;
      line-height: $card-subtitle-line_height;
      text-transform: $card-subtitle-text_transform;
      text-shadow: $card-subtitle-text_shadow;
      text-align: $card-subtitle-text_align;
      color: $card-subtitle-color;

      @include media-breakpoint-up(sm) {
        font-size: $card-subtitle-font_size_sm;
        line-height: $card-subtitle-line_height_sm;
      }
    }

    .card-description {
      @extend .position-relative;
      margin: $card-description-margin;
      overflow: hidden;

      font-family: $card-description-font_family;
      font-size: $card-description-font_size;
      font-style: $card-description-font_style;
      font-weight: $card-description-font_weight;
      line-height: $card-description-line_height;
      text-transform: $card-description-text_transform;
      text-shadow: $card-description-text_shadow;
      text-align: $card-description-text_align;
      color: $card-description-color;

      @include media-breakpoint-up(sm) {
        min-height: $card-description-max_height;
        max-height: $card-description-max_height;
      }

      &::after {
        @extend .position-absolute;
        right: 0;
        bottom: 0;
        left: 0;

        @extend .d-none;
        @extend .d-sm-block;
        height: $card-description-fade-height;
        background: $card-body-background;
        background: $card-description-fade-background;

        content: '';
      }

      .list {
        .list-item {
          color: inherit;
        }
      }
    }
  }

  .card-buttons {
    flex: $card-buttons-flex;
    margin: $card-buttons-margin;
  }

  .card-btn {
    @extend .btn;

    background: $primary;
    border: $btn-border-width solid $primary;

    color: $white;

    &:hover {
      background: $black;
      border: $btn-border-width solid $black;

      color: $white;
    }
  }
}

/* card > card-overlay
   ========================================================================== */
.card.card-overlay {
  .card-image {
    .card-image-caption {
      @extend .d-none;
    }
  }

  .card-img-overlay {
    display: $card-overlay-display;
    flex-direction: $card-overlay-flex_direction;
    padding: $card-overlay-padding;
    background: $card-overlay-background;
    overflow: hidden;
  }

  .card-caption {
    padding: $card-overlay-caption-padding;
    background: $card-overlay-caption-background;

    .card-title {
      margin: $card-overlay-title-margin;

      font-family: $card-overlay-title-font_family;
      font-size: $card-overlay-title-font_size;
      font-style: $card-overlay-title-font_style;
      font-weight: $card-overlay-title-font_weight;
      line-height: $card-overlay-title-line_height;
      text-shadow: $card-overlay-title-text_shadow;
      color: $card-overlay-title-color;

      @include media-breakpoint-up(sm) {
        font-size: $card-overlay-title-font_size_sm;
        line-height: $card-overlay-title-line_height_sm;
      }
    }

    .card-title-link {
      margin: $card-overlay-title-margin;
      text-decoration: $card-overlay-title-link-text_decoration;
      color: $card-overlay-title-link-color;

      .card-title { color: inherit; }

      &:hover {
        text-decoration: $card-overlay-title-link-text_decoration--hover;
        color: $card-overlay-title-link-color--hover;
      }
    }

    .card-subtitle {
      margin: $card-overlay-subtitle-margin;

      font-family: $card-overlay-subtitle-font_family;
      font-size: $card-overlay-subtitle-font_size;
      font-style: $card-overlay-subtitle-font_style;
      font-weight: $card-overlay-subtitle-font_weight;
      line-height: $card-overlay-subtitle-line_height;
      text-shadow: $card-overlay-subtitle-text_shadow;
      color: $card-overlay-subtitle-color;

      @include media-breakpoint-up(sm) {
        font-size: $card-overlay-subtitle-font_size_sm;
        line-height: $card-overlay-subtitle-line_height_sm;
      }
    }

    .card-description {
      @extend .d-none;
      @extend .d-md-block;
      min-height: $card-overlay-description-max_height;
      max-height: $card-overlay-description-max_height;
      margin: $card-overlay-description-margin;

      font-family: $card-overlay-description-font_family;
      font-size: $card-overlay-description-font_size;
      font-style: $card-overlay-description-font_style;
      font-weight: $card-overlay-description-font_weight;
      line-height: $card-overlay-description-line_height;
      text-shadow: $card-overlay-description-text_shadow;
      color: $card-overlay-description-color;

      &::after {
        height: $card-overlay-description-fade-height;
        background: $card-overlay-background;
        background: $card-overlay-description-fade-background;
      }
    }
  }

  .card-btn {
    background: transparent;
    border: $btn-border-width solid $white;

    color: $white;

    &:hover {
      background: $white;
      border: $btn-border-width solid $white;

      color: $body-color;
    }
  }
}

/* card > card-assortiment
   ========================================================================== */
.card.card-assortiment {

  .card-price.discounted { text-decoration: line-through; }

  .card-caption {
    .card-prices {
      margin: $card-prices-margin;

      font-family: $card-prices-font_family;
      font-size: $card-prices-font_size;
      font-style: $card-prices-font_style;
      font-weight: $card-prices-font_weight;
      line-height: $card-prices-line_height;
      text-shadow: $card-prices-text_shadow;
      text-align: $card-prices-text_align;
      color: $card-prices-color;

      .card-price {
        @extend .text-truncate;

        font-family: $card-prices-price-font_family;
        font-size: $card-prices-price-font_size;
        font-style: $card-prices-price-font_style;
        font-weight: $card-prices-price-font_weight;
        color: $card-prices-price-color;
      }

      .card-price-message {
        @extend .text-truncate;
      }
    }
  }
}

/* card > card-arrangement
   ========================================================================== */
.card.card-arrangement {

  .card-image {
    overflow: visible;
    .card-label {
      position: absolute;
      right: 0;
      bottom: -10px;
      margin: 0 15px;
      background: $primary;
      padding: $btn-padding-y-lg $btn-padding-x-lg;
      border-radius: $border-radius;
      color: $white;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 10px;
        width: 0;
        height: 0;
        border-top: 10px solid $primary;
        border-right: 10px solid transparent;
      }
    }
  }

  .card-info {
    margin-bottom: 1rem;

    .card-staydata {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 0.5rem 0;
      padding: 0;
      list-style: none;
      li {
        font-size: $font-size-base * 0.75;
        line-height: 1;
        + li {
          &::before {
            content: '|';
            margin: 0 5px;
          }
        }
      }
    }
    .card-traveldata {
      margin-bottom: 1rem;
      .dates {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 0.5rem 0;
        padding: 0;
        list-style: none;
        li {
          color: $primary;
          line-height: 1;
          font-size: $font-size-sm;
          + li {
            &::before {
              content: '\f061';
              margin: 0 7.5px;
              color: rgba($primary, 0.5);
              font-weight: 900;
              font-family: $font-awesome;
            }
          }
        }
      }
    }
    .card-prices {
      text-align: right;
      .card-old-price {
        font-size: $font-size-sm;
        line-height: 1;
        text-decoration: line-through;
      }
      .card-price {
        .card-price-prefix {
          font-size: $font-size-base * 0.75;
        }
        .card-price-amount {
          margin-left: 5px;
          color: $primary;
          font-size: $font-size-lg;
          font-weight: 700;
        }
      }
    }
  }

  .card-characteristics {
    margin: 0;
  }

  .card-buttons {
    margin: 0;
  }
}

/* card > card-wide
   ========================================================================== */
.card.card-wide {
  @extend .flex-row;
  @extend .flex-wrap;

  .card-image {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(5);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }
  }

  .card-body {
    @include make-col(12);

    @include media-breakpoint-up(sm) {
      @include make-col(12);
    }

    @include media-breakpoint-up(md) {
      @include make-col(7);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(8);
    }
  }
}

/* card > card-icon
   ========================================================================== */
.card.icon {
  .card-image {
    background: $card-icon-image-background;

    font-size: 155px;
    @extend .text-center;
  }
}

/* card > card-dark
   ========================================================================== */
.card.dark {
  background: $card-background--dark;
  border: $card-border--dark;

  .card-image {
    background: $card-image-background--dark;
  }

  .card-body {
    background: $card-body-background--dark;
  }

  .card-caption {
    .card-title {
      color: $card-title-color--dark;
    }

    .card-title-link {
      color: $card-title-link-color--dark;

      .card-title {
        margin: 0;
        color: inherit;
      }

      &:hover {
        color: $card-title-link-color--hover;
      }
    }

    .card-subtitle {
      color: $card-subtitle-color--dark;
    }

    .card-description {
      color: $card-description-color--dark;

      &::after {
        background: $card-body-background--dark;
        background: $card-description-fade-background--dark;
      }
    }

    .card-prices {
      color: $card-prices-color--dark;

      .card-price {
        color: $card-prices-price-color--dark;
      }
    }
  }

  .card-info {
    .card-prices {
      .card-price-label,
      .card-old-price {
        color: $white;
      }

      .card-price {
        color: $primary;
      }
    }

    .card-traveldata {
      .card-arrival-label,
      .card-arrival-data,
      .card-duration-data {
        color: $white;
      }
    }
  }

  .card-btn {
    background: $white;
    border: $btn-border-width solid $white;

    color: $body-color;

    &:hover {
      background: $primary;
      border: $btn-border-width solid $primary;

      color: $white;
    }
  }
}

/* card > masonry
   ========================================================================== */
.masonry {
  .card {
    .card-caption {
      .card-description {
        min-height: 0;
        max-height: unset;

        &::after {
          @extend .d-none;
        }
      }
    }
  }
}

/* card > inspiration
   ========================================================================== */
.inspiration {
  .card.icon {
    .card-image {
      @extend .h-100;

      .card-image-link {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-center;

        @extend .h-100;
      }
    }
  }
}
