.loading-icon {
  &::before {
    @extend .d-block;
    width: $loading-icon-size;
    height: $loading-icon-size;
    margin: $loading-icon-margin;

    border: $loading-icon-border;
    border-color: $loading-icon-border_color;
    border-radius: $loading-icon-border_radius;

    content: '';
    animation: loading-icon-spin $loading-icon-animation_speed linear infinite;
  }
}

.loading-overlay {
  @extend .position-relative;

  @extend .loading-icon;
  &::before {
    @extend .position-absolute;
    top: 50%;
    left: 50%;

    margin: $loading-overlay-icon-margin;
  }
}

@keyframes loading-icon-spin {
  to { transform: rotate(360deg); }
}