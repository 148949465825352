&.accommodation_book {

	section.content {
		margin: 0 !important;
	}

	.container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		max-width: none !important;
	}
}
