/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 147px 0 0 0 !default;
$body-sticky-padding: 112px 0 0 0 !default;


/* ==========================================================================
   containers
   ========================================================================== */
$container-padding: 0 30px !default;
$container-padding_sm: 0 30px !default;

$container-small-max_width_sm: 540px !default;
$container-small-max_width_md: 720px !default;
$container-small-max_width_lg: 960px !default;
$container-small-max_width_xl: 1000px !default;

$container-fluid-max_width: 2000px !default;


/* ==========================================================================
   content
   ========================================================================== */
$content-padding: 8vh 0 !default;

/* content > h1
   ========================================================================== */
$content-h1-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h1-font_family: $headings-font-family !default;
$content-h1-font_size: $h1-font-size !default;
$content-h1-font_size_sm: $content-h1-font_size !default;
$content-h1-font_style: $headings-font-style !default;
$content-h1-font_weight: $headings-font-weight !default;
$content-h1-line_height: $headings-line-height !default;
$content-h1-line_height_sm: $content-h1-line_height !default;
$content-h1-color: $body-color !default;
$content-h1-color--dark: $white !default;

/* content > h2
   ========================================================================== */
$content-h2-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h2-font_family: $headings-font-family !default;
$content-h2-font_size: $h2-font-size !default;
$content-h2-font_size_sm: $content-h2-font_size !default;
$content-h2-font_style: $headings-font-style !default;
$content-h2-font_weight: $headings-font-weight !default;
$content-h2-line_height: $headings-line-height !default;
$content-h2-line_height_sm: $content-h2-line_height !default;
$content-h2-color: $body-color !default;
$content-h2-color--dark: $white !default;

/* content > h3
   ========================================================================== */
$content-h3-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h3-font_family: $headings-font-family !default;
$content-h3-font_size: $h3-font-size !default;
$content-h3-font_size_sm: $content-h3-font_size !default;
$content-h3-font_style: $headings-font-style !default;
$content-h3-font_weight: $headings-font-weight !default;
$content-h3-line_height: $headings-line-height !default;
$content-h3-line_height_sm: $content-h3-line_height !default;
$content-h3-color: $body-color !default;
$content-h3-color--dark: $white !default;

/* content > h4
   ========================================================================== */
$content-h4-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h4-font_family: $headings-font-family !default;
$content-h4-font_size: $h4-font-size !default;
$content-h4-font_size_sm: $content-h4-font_size !default;
$content-h4-font_style: $headings-font-style !default;
$content-h4-font_weight: $headings-font-weight !default;
$content-h4-line_height: $headings-line-height !default;
$content-h4-line_height_sm: $content-h4-line_height !default;
$content-h4-color: $body-color !default;
$content-h4-color--dark: $white !default;

/* content > h5
   ========================================================================== */
$content-h5-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h5-font_family: $headings-font-family !default;
$content-h5-font_size: $h5-font-size !default;
$content-h5-font_size_sm: $content-h5-font_size !default;
$content-h5-font_style: $headings-font-style !default;
$content-h5-font_weight: $headings-font-weight !default;
$content-h5-line_height: $headings-line-height !default;
$content-h5-line_height_sm: $content-h5-line_height !default;
$content-h5-color: $body-color !default;
$content-h5-color--dark: $white !default;

/* content > h6
   ========================================================================== */
$content-h6-margin: 0 0 $headings-margin-bottom 0 !default;
$content-h6-font_family: $headings-font-family !default;
$content-h6-font_size: $h6-font-size !default;
$content-h6-font_size_sm: $content-h6-font_size !default;
$content-h6-font_style: $headings-font-style !default;
$content-h6-font_weight: $headings-font-weight !default;
$content-h6-line_height: $headings-line-height !default;
$content-h6-line_height_sm: $content-h6-line_height !default;
$content-h6-color: $body-color !default;
$content-h6-color--dark: $white !default;

/* content > p
   ========================================================================== */
$content-p-margin: 0 0 $paragraph-margin-bottom 0 !default;
$content-p-font_size: $font-size-base !default;
$content-p-font_style: $font-style-base !default;
$content-p-font_weight: $font-weight-base !default;
$content-p-line_height: $line-height-base !default;
$content-p-color: $body-color !default;
$content-p-color--dark: $white !default;

/* content > ul & ol
   ========================================================================== */
$content-list-item-margin: 0 !default;
$content-list-item-font_size: $font-size-base !default;
$content-list-item-font_style: $font-style-base !default;
$content-list-item-font_weight: $font-weight-base !default;
$content-list-item-line_height: $line-height-base !default;
$content-list-item-color: $body-color !default;
$content-list-item-color--dark: $white !default;

/* content > ul.custom-list
   ========================================================================== */
$content-custom-list-item-padding: 0 0 0 25px !default;

/* content > ul.custom-list > icon */
$content-custom-list-item-icon: '\f111' !default;
$content-custom-list-item-icon-height: 24px !default;
$content-custom-list-item-icon-font_size: 10px !default;
$content-custom-list-item-icon-font_weight: $font-weight-bold !default;
$content-custom-list-item-icon-line_height: 24px !default;
$content-custom-list-item-icon-color: $primary !default;
$content-custom-list-item-icon-color--dark: $primary !default;

/* content > ol.custom-list
   ========================================================================== */
$content-ol-custom-list-item-padding: $content-custom-list-item-padding !default;

/* content > ol.custom-list > icon */
$content-ol-custom-list-item-icon: $content-custom-list-item-icon !default;
$content-ol-custom-list-item-icon-height: $content-custom-list-item-icon-height !default;
$content-ol-custom-list-item-icon-font_size: $content-custom-list-item-icon-font_size !default;
$content-ol-custom-list-item-icon-font_weight: $font-weight-normal !default;
$content-ol-custom-list-item-icon-line_height: $content-custom-list-item-icon-line_height !default;
$content-ol-custom-list-item-icon-color: $content-custom-list-item-icon-color !default;
$content-ol-custom-list-item-icon-color--dark: $content-custom-list-item-icon-color--dark !default;


/* ==========================================================================
   mini-sab
   ========================================================================== */
$mini-sab-padding: 4vh 0 !default;
$mini-sab-background: $gray-300 !default;

/* ==========================================================================
   footer
   ========================================================================== */
$footer-padding: 0 !default;
$footer-background: transparent !default;

/* footer > footer-top
   ========================================================================== */
$footer-top-padding: 8vh 0 !default;
$footer-top-background: $secondary !default;

/* footer > footer-socket
   ========================================================================== */
$footer-socket-padding: 4vh 0 !default;
$footer-socket-background: $dark !default;


/* ==========================================================================
   card
   ========================================================================== */
$card-background: $card-bg !default;
$card-background--dark: $black !default;
$card-border: $card-border-width solid $card-border-color !default;
$card-border--dark: 0 !default;
$card-base-border-radius: $card-border-radius !default;
$card-box_shadow: none !default;

/* card > card-image
   ========================================================================== */
$card-image-flex: 0 0 auto !default;
$card-image-background: $gray-200 !default;
$card-image-background--dark: $dark !default;
$card-image-border_radius: 0 !default;

$card-icon-image-background: $white !default;

/* card > card-image-caption */
$card-image-caption-top: auto !default;
$card-image-caption-right: 0 !default;
$card-image-caption-bottom: 0 !default;
$card-image-caption-left: 0 !default;

$card-image-caption-display: block !default;
$card-image-caption-align_items: stretch !default;
$card-image-caption-justify_content: flex-start !default;
$card-image-caption-padding: 0 !default;
$card-image-caption-background: transparent !default;

/* card > card-image-label */
$card-image-label-padding: 5px 15px !default;
$card-image-label-background: rgba($black, .25) !default;

$card-image-label-font_family: $font-family-base !default;
$card-image-label-font_size: $font-size-base !default;
$card-image-label-font_style: $font-style-base !default;
$card-image-label-font_weight: $font-weight-base !default;
$card-image-label-line_height: $line-height-base !default;
$card-image-label-text_shadow: none !default;
$card-image-label-text_align: left !default;
$card-image-label-color: $white !default;

/* card > card-body
   ========================================================================== */
$card-body-padding: 15px !default;
$card-body-background: $card-background !default;
$card-body-background--dark: $card-background--dark !default;

/* card > card-caption
   ========================================================================== */
$card-caption-flex: 1 1 auto !default;
$card-caption-text_align: left !default;

/* card > card-title */
$card-title-margin: 0 0 $headings-margin-bottom 0 !default;
$card-title-font_family: $headings-font-family !default;
$card-title-font_size: $font-size-base * 1.5 !default;
$card-title-font_size_sm: $card-title-font_size !default;
$card-title-font_style: $font-style-base !default;
$card-title-font_weight: $headings-font-weight !default;
$card-title-line_height: 1.2 !default;
$card-title-line_height_sm: $card-title-line_height !default;
$card-title-text_transform: none !default;
$card-title-text_shadow: none !default;
$card-title-text_align: $card-caption-text_align !default;
$card-title-color: $body-color !default;
$card-title-color--dark: $white !default;

/* card > card-title-link */
$card-title-link-text_decoration: none !default;
$card-title-link-text_decoration--hover: none !default;
$card-title-link-color: $card-title-color !default;
$card-title-link-color--hover: $primary !default;
$card-title-link-color--dark: $card-title-color--dark !default;

/* card > card-subtitle */
$card-subtitle-margin: 0 0 $headings-margin-bottom 0 !default;
$card-subtitle-font_family: $headings-font-family !default;
$card-subtitle-font_size: $font-size-base !default;
$card-subtitle-font_size_sm: $card-subtitle-font_size !default;
$card-subtitle-font_style: $font-style-base !default;
$card-subtitle-font_weight: $headings-font-weight !default;
$card-subtitle-line_height: 1.2 !default;
$card-subtitle-line_height_sm: $card-subtitle-line_height !default;
$card-subtitle-text_transform: none !default;
$card-subtitle-text_shadow: none !default;
$card-subtitle-text_align: $card-caption-text_align !default;
$card-subtitle-color: $body-color !default;
$card-subtitle-color--dark: $white !default;

/* card > card-description */
$card-description-max_height: 125px !default;
$card-description-margin: 0 !default;
$card-description-font_family: $font-family-base !default;
$card-description-font_size: $font-size-base !default;
$card-description-font_style: $font-style-base !default;
$card-description-font_weight: $font-weight-base !default;
$card-description-line_height: $line-height-base !default;
$card-description-text_transform: none !default;
$card-description-text_shadow: none !default;
$card-description-text_align: $card-caption-text_align !default;
$card-description-color: $body-color !default;
$card-description-color--dark: $white !default;

$card-description-fade-height: 50px !default;
$card-description-fade-background: linear-gradient(180deg, rgba($card-body-background,0) 0%, rgba($card-body-background,1) 100%) !default;
$card-description-fade-background--dark: linear-gradient(180deg, rgba($card-body-background--dark,0) 0%, rgba($card-body-background--dark,1) 100%) !default;

/* card > card-prices */
$card-prices-margin: 0 !default;
$card-prices-font_family: $font-family-base !default;
$card-prices-font_size: $font-size-base !default;
$card-prices-font_style: $font-style-base !default;
$card-prices-font_weight: $font-weight-bold !default;
$card-prices-line_height: $line-height-base !default;
$card-prices-text_shadow: none !default;
$card-prices-text_align: $card-caption-text_align !default;
$card-prices-color: $body-color !default;
$card-prices-color--dark: $white !default;

$card-prices-price-font_family: $card-prices-font_family !default;
$card-prices-price-font_size: $card-prices-font_size !default;
$card-prices-price-font_style: $card-prices-font_style !default;
$card-prices-price-font_weight: $card-prices-font_weight !default;
$card-prices-price-color: $card-prices-color !default;
$card-prices-price-color--dark: $card-prices-color--dark !default;

/* card > card-buttons
   ========================================================================== */
$card-buttons-flex: 0 0 auto !default;
$card-buttons-margin: 15px 0 0 0 !default;

/* card > card-overlay
   ========================================================================== */
$card-overlay-display: block !default;
$card-overlay-flex_direction: column !default;
$card-overlay-padding: $card-body-padding !default;
$card-overlay-background: rgba($black, .25) !default;

$card-overlay-caption-padding: 0 !default;
$card-overlay-caption-background: transparent !default;

/* card > card-overlay-title */
$card-overlay-title-margin: $card-title-margin !default;
$card-overlay-title-font_family: $card-title-font_family !default;
$card-overlay-title-font_size: $card-title-font_size !default;
$card-overlay-title-font_size_sm: $card-title-font_size_sm !default;
$card-overlay-title-font_style: $card-title-font_style !default;
$card-overlay-title-font_weight: $card-title-font_weight !default;
$card-overlay-title-line_height: $card-title-line_height !default;
$card-overlay-title-line_height_sm: $card-title-line_height_sm !default;
$card-overlay-title-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$card-overlay-title-color: $white !default;

/* card > card-overlay-title-link */
$card-overlay-title-link-text_decoration: $card-title-link-text_decoration !default;
$card-overlay-title-link-text_decoration--hover: $card-title-link-text_decoration--hover !default;
$card-overlay-title-link-color: $card-overlay-title-color !default;
$card-overlay-title-link-color--hover: $card-title-link-color--hover !default;

/* card > card-overlay-subtitle */
$card-overlay-subtitle-margin: $card-subtitle-margin !default;
$card-overlay-subtitle-font_family: $card-subtitle-font_family !default;
$card-overlay-subtitle-font_size: $card-subtitle-font_size !default;
$card-overlay-subtitle-font_size_sm: $card-subtitle-font_size_sm !default;
$card-overlay-subtitle-font_style: $card-subtitle-font_style !default;
$card-overlay-subtitle-font_weight: $card-subtitle-font_weight !default;
$card-overlay-subtitle-line_height: $card-subtitle-line_height !default;
$card-overlay-subtitle-line_height_sm: $card-subtitle-line_height_sm !default;
$card-overlay-subtitle-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$card-overlay-subtitle-color: $white !default;

/* card > card-overlay-description */
$card-overlay-description-max_height: 75px !default;
$card-overlay-description-margin: $card-description-margin !default;
$card-overlay-description-font_family: $card-description-font_family !default;
$card-overlay-description-font_size: $card-description-font_size !default;
$card-overlay-description-font_style: $card-description-font_style !default;
$card-overlay-description-font_weight: $card-description-font_weight !default;
$card-overlay-description-line_height: $card-description-line_height !default;
$card-overlay-description-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$card-overlay-description-color: $white !default;

$card-overlay-description-fade-height: $card-description-fade-height !default;
$card-overlay-description-fade-background: transparent !default;


/* ==========================================================================
   grid
   ========================================================================== */
$grid-justify_content: center !default;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
$carousel-align_items: center !default;
$carousel-flex: 0 0 auto !default;

$carousel-min_height: 35vh !default;
$carousel-min_height_lg: 70vh !default;

$carousel-item_padding: 7.5vh 0 !default;
$carousel-item_padding_lg: 15vh 0 !default;
$carousel-item-background_position: center center !default;
$carousel-item-background_repeat: no-repeat !default;
$carousel-item-background_size: cover !default;

$carousel-caption-padding: 0 !default;
$carousel-caption-background: transparent !default;
$carousel-caption-text_align: left !default;

$carousel-title-margin: 0 0 $headings-margin-bottom 0 !default;
$carousel-title-font_family: $headings-font-family !default;
$carousel-title-font_size: $h2-font-size !default;
$carousel-title-font_size_sm: $carousel-title-font_size !default;
$carousel-title-font_style: $font-style-base !default;
$carousel-title-font_weight: $headings-font-weight !default;
$carousel-title-line_height: 1.2 !default;
$carousel-title-line_height_sm: $carousel-title-line_height !default;
$carousel-title-text_transform: none !default;
$carousel-title-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$carousel-title-color: $white !default;

$carousel-subtitle-margin: 0 0 $headings-margin-bottom 0 !default;
$carousel-subtitle-font_family: $headings-font-family !default;
$carousel-subtitle-font_size: $h3-font-size !default;
$carousel-subtitle-font_size_sm: $carousel-subtitle-font_size !default;
$carousel-subtitle-font_style: $font-style-base !default;
$carousel-subtitle-font_weight: $headings-font-weight !default;
$carousel-subtitle-line_height: 1.2 !default;
$carousel-subtitle-line_height_sm: $carousel-subtitle-line_height !default;
$carousel-subtitle-text_transform: none !default;
$carousel-subtitle-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$carousel-subtitle-color: $white !default;

$carousel-description-margin: 0 0 $paragraph-margin-bottom 0 !default;
$carousel-description-font_family: $font-family-base !default;
$carousel-description-font_size: $font-size-base !default;
$carousel-description-font_style: $font-style-base !default;
$carousel-description-font_weight: $font-weight-base !default;
$carousel-description-line_height: $line-height-base !default;
$carousel-description-text_transform: none !default;
$carousel-description-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$carousel-description-color: $white !default;

/* owl-carousel > owl-nav */
$carousel-nav-bottom: 50px !default;
$carousel-nav-bottom_md: 50% !default;
$carousel-nav-margin: 0 0 25px 0 !default;

$carousel-nav-button-width: 30px !default;
$carousel-nav-button-height: 50px !default;
$carousel-nav-button-font_size: $font-size-base * 2 !default;
$carousel-nav-button-line_height: 50px !default;
$carousel-nav-button-text_shadow: 2px 2px 2px rgba($body-color,.5) !default;
$carousel-nav-button-color: $white !default;
$carousel-nav-button-color--hover: $primary !default;

/* owl-carousel.slider > owl-nav */
$slider-nav-button-text_shadow: none !default;
$slider-nav-button-color: $primary !default;
$slider-nav-button-color--hover: darken($primary, 15%) !default;
$slider-nav-button-color--dark: $white !default;
$slider-nav-button-color--dark--hover: $primary !default;

/* owl-carousel > owl-dots */
$carousel-dots-button-size: 15px !default;
$carousel-dots-button-width: $carousel-dots-button-size !default;
$carousel-dots-button-height: $carousel-dots-button-size !default;
$carousel-dots-button-margin: 7.5px 2.5px !default;
$carousel-dots-button-background: transparent !default;
$carousel-dots-button-background--hover: $white !default;
$carousel-dots-button-background--active: $white !default;
$carousel-dots-button-border: 2px solid $white !default;
$carousel-dots-button-border--hover: 2px solid $white !default;
$carousel-dots-button-border--active: 2px solid $white !default;
$carousel-dots-button-border_radius: 15px !default;
$carousel-dots-button-box_shadow: 2px 2px 2px rgba($body-color,.5) !default;

/* owl-carousel.slider > owl-dots */
$slider-dots-button-background: transparent !default;
$slider-dots-button-background--dark: $slider-dots-button-background !default;
$slider-dots-button-background--hover: $primary !default;
$slider-dots-button-background--dark--hover: $white !default;
$slider-dots-button-background--active: $primary !default;
$slider-dots-button-background--dark--active: $white !default;
$slider-dots-button-border: 2px solid $primary !default;
$slider-dots-button-border--dark: 2px solid $white !default;
$slider-dots-button-border--hover: 2px solid $primary !default;
$slider-dots-button-border--dark--hover: 2px solid $white !default;
$slider-dots-button-border--active: 2px solid $primary !default;
$slider-dots-button-border--dark--active: 2px solid $white !default;
$slider-dots-button-box_shadow: none !default;

/* owl-carousel > eyecatcher
   ========================================================================== */
$eyecatcher-min_height: $carousel-min_height !default;
$eyecatcher-min_height_lg: $carousel-min_height_lg !default;


/* ==========================================================================
   gallery
   ========================================================================== */
$gallery-margin: -10px -5px 0 -5px !default;
$gallery-margin_sm: -20px -10px 0 -10px !default;

$gallery-item-margin: 10px 0 0 0 !default;
$gallery-item-margin_sm: 20px 0 0 0 !default;
$gallery-item-padding: 0 5px !default;
$gallery-item-padding_sm: 0 10px !default;

$gallery-caption-background: rgba($black,.5) !default;

$gallery-caption-font_size: $font-size-base * 1.5 !default;
$gallery-caption-font_size_sm: $font-size-base * 2 !default;
$gallery-caption-color: $white !default;


/* ==========================================================================
   alert
   ========================================================================== */
$alert-margin: 0 0 $alert-margin-bottom 0 !default;
$alert-padding: $alert-padding-y $alert-padding-x !default;
$alert-base-border_radius: $alert-border-radius !default;
$alert-box_shadow: none !default;

$alert-font_family: $font-family-base !default;
$alert-font_size: $font-size-base !default;
$alert-font_style: $font-style-base !default;
$alert-font_weight: $font-weight-base !default;
$alert-line_height: $line-height-base !default;

/* alert > alert-danger
   ========================================================================== */
$alert-danger-background: $white !default;
$alert-danger-background--dark: transparent !default;
$alert-danger-border: 1px solid $danger !default;
$alert-danger-border--dark: $alert-danger-border !default;

$alert-danger-color: $danger !default;
$alert-danger-color--dark: $alert-danger-color !default;


/* ==========================================================================
   form
   ========================================================================== */
$form-group-margin: 0 0 $form-group-margin-bottom 0 !default;

/* form > form-control-label
   ========================================================================== */
$form-control-label-margin: 0 0 $label-margin-bottom 0 !default;

$form-control-label-font_family: $font-family-base !default;
$form-control-label-font_size: $font-size-base !default;
$form-control-label-font_style: $font-style-base !default;
$form-control-label-font_weight: $font-weight-base !default;
$form-control-label-line_height: $line-height-base !default;
$form-control-label-color: $body-color !default;
$form-control-label-color--dark: $white !default;

/* form > form-control
   ========================================================================== */
$form-control-padding: $input-padding-y $input-padding-x !default;
$form-control-background_color: $input-bg !default;
$form-control-background_color--dark: $form-control-background_color !default;
$form-control-border: $input-border-width solid $input-border-color !default;
$form-control-border--dark: $form-control-border !default;
$form-control-border--invalid: $input-border-width solid $danger !default;
$form-control-border--invalid--dark: $form-control-border--invalid !default;
$form-control-border_radius: $input-border-radius !default;
$form-control-box_shadow: $input-box-shadow !default;

$form-control-font_family: $input-font-family !default;
$form-control-font_size: $input-font-size !default;
$form-control-font_style: $font-style-base !default;
$form-control-font_weight: $input-font-weight !default;
$form-control-line_height: $input-line-height !default;
$form-control-color: $input-color !default;
$form-control-color--dark: $form-control-color !default;

/* form > reCAPTCHA
   ========================================================================== */
$form-reCAPTCHA-margin: 0 0 $form-group-margin-bottom 0 !default;

$form-reCAPTCHA-font_size: $font-size-base * .75 !default;
$form-reCAPTCHA-font_style: $font-style-base !default;
$form-reCAPTCHA-font_weight: $font-weight-base !default;
$form-reCAPTCHA-line_height: $line-height-base !default;
$form-reCAPTCHA-color: $body-color !default;
$form-reCAPTCHA-color--dark: $white !default;


/* ==========================================================================
   loading
   ========================================================================== */
/* loading > icon
   ========================================================================== */
$loading-icon-size: 32px !default;
$loading-icon-margin: 0 auto !default;

$loading-icon-border: 4px solid $gray-100 !default;
$loading-icon-border_color: $secondary $secondary $secondary transparent !default;
$loading-icon-border_radius: 50% !default;

$loading-icon-animation_speed: .75s !default;

/* loading > overlay > icon
   ========================================================================== */
$loading-overlay-icon-margin: ($loading-icon-size / -2) 0 0 ($loading-icon-size / -2) !default;


/* ==========================================================================
   faq
   ========================================================================== */
/* faq > category
   ========================================================================== */
$faq-category-margin: 15px 0 0 0 !default;

$faq-category-title-margin: 0 !default;
$faq-category-title-padding: 10px 0 10px 50px !default;
$faq-category-title-padding_sm: 10px 10px 10px 50px !default;

$faq-category-title-background: transparent !default;

$faq-category-title-font_family: $headings-font-family !default;
$faq-category-title-font_size: $h4-font-size !default;
$faq-category-title-font_size_sm: $faq-category-title-font_size !default;
$faq-category-title-font_style: $headings-font-style !default;
$faq-category-title-font_weight: $headings-font-weight !default;
$faq-category-title-line_height: $headings-line-height !default;
$faq-category-title-line_height_sm: $faq-category-title-line_height !default;
$faq-category-title-text_transform: none !default;
$faq-category-title-text_decoration: none !default;
$faq-category-title-color: $body-color !default;

/* faq > question
   ========================================================================== */
$faq-question-margin: 0 0 5px 0 !default;
$faq-question-padding: 10px 10px 10px 50px !default;
$faq-question-padding_sm: 10px 10px 10px 50px !default;

$faq-question-background: transparent !default;
$faq-question-border: 1px solid $gray-200 !default;

$faq-question-font_family: $headings-font-family !default;
$faq-question-font_size: $font-size-base !default;
$faq-question-font_size_sm: $faq-question-font_size !default;
$faq-question-font_style: $headings-font-style !default;
$faq-question-font_weight: $headings-font-weight !default;
$faq-question-line_height: $headings-line-height !default;
$faq-question-line_height_sm: $faq-question-line_height !default;
$faq-question-text_transform: none !default;
$faq-question-text_decoration: none !default;
$faq-question-color: $body-color !default;

/* faq > answer
   ========================================================================== */
$faq-answer-margin: 0 0 25px 0 !default;
$faq-answer-padding: 10px 10px 10px 50px !default;
$faq-answer-padding_sm: 10px 10px 10px 50px !default;

$faq-answer-background: transparent !default;
$faq-answer-border: 1px solid $gray-200 !default;

$faq-answer-font_family: $font-family-base !default;
$faq-answer-font_size: $font-size-base !default;
$faq-answer-font_style: $font-style-base !default;
$faq-answer-font_weight: $font-weight-base !default;
$faq-answer-line_height: $line-height-base !default;
$faq-answer-text_transform: none !default;
$faq-answer-text_decoration: none !default;
$faq-answer-color: $body-color !default;

/* faq > icon
   ========================================================================== */
$faq-icon-top: 0 !default;
$faq-icon-right: unset !default;
$faq-icon-bottom: unset !default;
$faq-icon-left: 0 !default;

$faq-icon-width: 50px !default;

$faq-icon-font_size: $font-size-base !default;
$faq-icon-line_height: 41px !default;
$faq-icon-color: $primary !default;
$faq-icon-color--hover: darken($primary, 15%) !default;

$faq-category-title-icon-line_height: 48px !default;


/* ==========================================================================
   timeslot
   ========================================================================== */
$timeslot-global-row-margin: 0 -15px -30px -15px !default;

/* timeslot > overview
   ========================================================================== */
$timeslot-overview-margin: 0 0 30px 0 !default;

$timeslot-overview-row-justify_content: flex-start !default;
$timeslot-overview-row-margin: $timeslot-global-row-margin !default;

/* timeslot > category
   ========================================================================== */
$timeslot-category-margin: 0 0 30px 0 !default;

$timeslot-category-row-justify_content: flex-start !default;
$timeslot-category-row-margin: $timeslot-global-row-margin !default;

/* timeslot > category > content */
$timeslot-category-content-margin: 0 0 30px 0 !default;

/* timeslot > category > title
   ========================================================================== */
$timeslot-category-title-margin: 0 0 $headings-margin-bottom 0 !default;

$timeslot-category-title-font_family: $headings-font-family !default;
$timeslot-category-title-font_size: $h2-font-size !default;
$timeslot-category-title-font_style: normal !default;
$timeslot-category-title-font_weight: $headings-font-weight !default;
$timeslot-category-title-line_height: $headings-line-height !default;
$timeslot-category-title-text_transform: none !default;
$timeslot-category-title-text_decoration: none !default;
$timeslot-category-title-color: $body-color !default;

/* timeslot > highlight
   ========================================================================== */
$timeslot-highlight-margin: 0 0 30px 0 !default;

$timeslot-highlight-row-justify_content: flex-start !default;
$timeslot-highlight-row-margin: $timeslot-global-row-margin !default;

/* timeslot > datepicker
   ========================================================================== */
$timeslot-datepicker-padding: 15px !default;

$timeslot-datepicker-background: $primary !default;
$timeslot-datepicker-border: 1px solid $primary !default;
$timeslot-datepicker-border_radius: $border-radius !default;
$timeslot-datepicker-box_shadow: none !default;

/* timeslot > datepicker > calendar
   ========================================================================== */
$timeslot-datepicker-picker-padding: 15px !default;

$timeslot-datepicker-picker-background: $white !default;
$timeslot-datepicker-picker-border: 1px solid $white !default;
$timeslot-datepicker-picker-border_radius: $border-radius !default;

/* timeslot > datepicker > calendar > item */
$timeslot-datepicker-calendar-item-background--active: $primary !default;
$timeslot-datepicker-calendar-item-color--active: $body-color !default;

/* timeslot > item
   ========================================================================== */
$timeslot-item-col: 12 !default;
$timeslot-item-col_sm: 12 !default;
$timeslot-item-col_md: 6 !default;
$timeslot-item-col_lg: 6 !default;
$timeslot-item-col_xl: 4 !default;

$timeslot-item-margin: 0 0 30px 0 !default;
$timeslot-item-padding: 15px !default;

$timeslot-item-background: $white !default;
$timeslot-item-border: 1px solid $gray-400 !default;
$timeslot-item-border_radius: $border-radius !default;
$timeslot-item-box_shadow: none !default;

/* timeslot > item > title
   ========================================================================== */
$timeslot-item-title-margin: 0 0 $headings-margin-bottom 0 !default;

$timeslot-item-title-font_family: $headings-font-family !default;
$timeslot-item-title-font_size: $h3-font-size !default;
$timeslot-item-title-font_style: normal !default;
$timeslot-item-title-font_weight: $headings-font-weight !default;
$timeslot-item-title-line_height: $headings-line-height !default;
$timeslot-item-title-text_transform: none !default;
$timeslot-item-title-text_decoration: none !default;
$timeslot-item-title-color: $body-color !default;

/* timeslot > item > navigation
   ========================================================================== */
$timeslot-item-navigation-padding: 5px 0 !default;

$timeslot-item-navigation-background: transparent !default;

/* timeslot > item > navigation > btn */
$timeslot-item-navigation-btn-padding: 5px 10px !default;

$timeslot-item-navigation-btn-background_color: $primary !default;
$timeslot-item-navigation-btn-background_color--hover: $secondary !default;
$timeslot-item-navigation-btn-border: 1px solid $primary !default;
$timeslot-item-navigation-btn-border--hover: 1px solid $secondary !default;
$timeslot-item-navigation-btn-border_radius: $btn-border-radius !default;
$timeslot-item-navigation-btn-box_shadow: none !default;

$timeslot-item-navigation-btn-font_size: $font-size-base !default;
$timeslot-item-navigation-btn-font_weight: $font-weight-bold !default;
$timeslot-item-navigation-btn-line_height: $line-height-base !default;
$timeslot-item-navigation-btn-color: $white !default;
$timeslot-item-navigation-btn-color--hover: $white !default;

/* timeslot > item > days
   ========================================================================== */
$timeslot-item-days-margin: -1px 0 0 0 !default;

/* timeslot > item > day
   ========================================================================== */
$timeslot-item-day-margin: 1px -.5px 0 -.5px !default;

/* timeslot > item > day > holders */
$timeslot-item-day-holders-padding: 0 .5px 0 .5px !default;

$timeslot-item-day-holders-col: 12 !default;
$timeslot-item-day-holders-col_sm: 6 !default;
$timeslot-item-day-holders-col_md: 6 !default;
$timeslot-item-day-holders-col_lg: 6 !default;
$timeslot-item-day-holders-col_xl: 6 !default;

/* timeslot > item > day > title
   ========================================================================== */
$timeslot-item-day-title-padding: 5px 10px !default;

$timeslot-item-day-title-background: $gray-300 !default;

$timeslot-item-day-title-font_size: $font-size-base !default;
$timeslot-item-day-title-font_style: normal !default;
$timeslot-item-day-title-font_weight: $font-weight-bold !default;
$timeslot-item-day-title-line_height: $line-height-base !default;
$timeslot-item-day-title-text_transform: none !default;
$timeslot-item-day-title-text_decoration: none !default;
$timeslot-item-day-title-color: $body-color !default;

/* timeslot > item > day > times
   ========================================================================== */
$timeslot-item-day-times-padding: 5px 10px !default;

$timeslot-item-day-times-background: $gray-200 !default;

$timeslot-item-day-times-font_size: $font-size-base !default;
$timeslot-item-day-times-font_style: normal !default;
$timeslot-item-day-times-font_weight: $font-weight-base !default;
$timeslot-item-day-times-line_height: $line-height-base !default;
$timeslot-item-day-times-text_transform: none !default;
$timeslot-item-day-times-text_decoration: none !default;
$timeslot-item-day-times-color: $body-color !default;

/* timeslot > item > day > times > info
   ========================================================================== */
$timeslot-item-day-times-info-popup-top: 30px !default;
$timeslot-item-day-times-info-popup-right: unset !default;
$timeslot-item-day-times-info-popup-bottom: unset !default;
$timeslot-item-day-times-info-popup-left: 50% !default;

$timeslot-item-day-times-info-popup-width: 250px !default;
$timeslot-item-day-times-info-popup-margin: 0 0 0 -125px !default;
$timeslot-item-day-times-info-popup-padding: 15px !default;

$timeslot-item-day-times-info-popup-background: #FFFFFF !default;
$timeslot-item-day-times-info-popup-border: 1px solid #FFFFFF !default;
$timeslot-item-day-times-info-popup-border_radius: $border-radius !default;
$timeslot-item-day-times-info-popup-box_shadow: 0 3px 3px rgba(0, 0, 0, 0.1) !default;

$timeslot-item-day-times-info-popup-font_size: $font-size-base * .875 !default;
$timeslot-item-day-times-info-popup-line_height: $line-height-base !default;
$timeslot-item-day-times-info-popup-color: $body-color !default;

/* timeslot > item > day > times > info > arrow */
$timeslot-item-day-times-info-arrow-top: unset !default;
$timeslot-item-day-times-info-arrow-right: unset !default;
$timeslot-item-day-times-info-arrow-bottom: 100% !default;
$timeslot-item-day-times-info-arrow-left: 50% !default;

$timeslot-item-day-times-info-arrow-margin: 0 0 0 -10px !default;

$timeslot-item-day-times-info-arrow-border: 10px solid transparent !default;
$timeslot-item-day-times-info-arrow-border_top_color: transparent !default;
$timeslot-item-day-times-info-arrow-border_right_color: transparent !default;
$timeslot-item-day-times-info-arrow-border_bottom_color: #FFFFFF !default;
$timeslot-item-day-times-info-arrow-border_left_color: transparent !default;