.home-blocks {
  .page_block {
    &.contentblock {
      &.grid {
        .item {
          @include media-breakpoint-up(xl) {
            @include make-col(4);
          }
        }
      }
    }
  }
}