.carousel {
  min-height: $eyecatcher-min_height;

  @extend .loading-overlay;

  .carousel-item {
    min-height: $eyecatcher-min_height;
    padding: $carousel-item_padding;

    .carousel-holder {
      @extend .position-absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @extend .d-flex;
      @extend .align-items-center;
    }

    .carousel-container {
      @extend .container;
    }
    
    .carousel-caption {
      @extend .position-relative;
      right: unset;
      bottom: unset;
      left: unset;

      padding: $carousel-caption-padding;
      background: $carousel-caption-background;

      text-align: $carousel-caption-text_align;
    }

    .carousel-title {
      margin: $carousel-title-margin;

      font-family: $carousel-title-font_family;
      font-size: $carousel-title-font_size;
      font-style: $carousel-title-font_style;
      font-weight: $carousel-title-font_weight;
      line-height: $carousel-title-line_height;
      text-shadow: $carousel-title-text_shadow;
      color: $carousel-title-color;
    }
    
    .carousel-subtitle {
      margin: $carousel-subtitle-margin;

      font-family: $carousel-subtitle-font_family;
      font-size: $carousel-subtitle-font_size;
      font-style: $carousel-subtitle-font_style;
      font-weight: $carousel-subtitle-font_weight;
      line-height: $carousel-subtitle-line_height;
      text-shadow: $carousel-subtitle-text_shadow;
      color: $carousel-subtitle-color;
    }

    .carousel-description {
      margin: $carousel-description-margin;

      font-family: $carousel-description-font_family;
      font-size: $carousel-description-font_size;
      font-style: $carousel-description-font_style;
      font-weight: $carousel-description-font_weight;
      line-height: $carousel-description-line_height;
      text-shadow: $carousel-description-text_shadow;
      color: $carousel-description-color;

      .list {
        .list-item {
          color: $carousel-description-color;
        }
      }
    }

    .carousel-btn {
      @extend .btn;
      @extend .btn-primary;
      @extend .shadow;

      &:hover {
        @extend .btn-secondary;
      }
    }
  }

  .carousel-item-bg {
    @extend .d-flex;
    @extend .align-items-center;

    @extend .h-100;

    background-position: $carousel-item-background_position;
    background-repeat: $carousel-item-background_repeat;
    background-size: $carousel-item-background_size;
  }
}

/* carousel > large
   ========================================================================== */
.carousel.large {
  min-height: $carousel-min_height_lg;
  
  .carousel-item {
    min-height: $carousel-min_height_lg;
    padding: $carousel-item_padding_lg;
  }
}