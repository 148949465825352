// footer
.footer {
	margin-top: 6vh;
	padding-bottom: 30px;

	// footer-outro
	.footer-outro {
		margin-bottom: 4vh;
		color: $secondary;

		h4 {
			@include font-size($h3-font-size);
			margin-bottom: 15px;
		}

		a[href^="tel:"] {
			@include font-size($h3-font-size);
			font-weight: 700;
			line-height: 1;
			text-decoration: none;

			&::before {
				content: "\f095";
				font-family: $font-awesome;
				margin-right: 10px;
				font-size: smaller;
				font-weight: 900;
			}
		}

		a.btn {
			background-color: $primary;
			border-color: $primary;
			text-transform: none;

			@include media-breakpoint-up(xl) {
				padding: 1rem 1.5rem;
			}
		}

		.social {
			margin: 15px 0;

			li {
				margin-right: 15px;

				a {
					display: block;
					color: $secondary;
					font-size: 36px;
					line-height: 1;

					&:hover {
						color: $primary;
					}

					span {
						display: none;
					}
				}
			}
		}
	}

	// footer-visual
	.footer-visual {
		height: 98px;
		background: url("/images/garland-flags-transparent.svg") repeat-x center center $primary;
		background-size: auto 98px;
		background-position-x: 100vw;
		animation: movingBg 120s linear infinite;

		@keyframes movingBg {
			50% {
				background-position-x: 0vw;
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		margin-top: 4vh;
		color: $primary;
		font-size: 16px;

		@include media-breakpoint-down(md) {
			.column {
				margin-bottom: 30px;
			}
		}

		h5 {
			font-size: 18px;
		}

		ul {
			@extend .list;

			li {
				a {
					color: $primary;

					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}

	// footer-logo
	.footer-logo {
		margin: 4vh 0 6vh 0;

		.logo {
			display: flex;

			@include media-breakpoint-up(md) {
				justify-content: center;
			}

			a {
				display: block;

				img {
					@include media-breakpoint-down(lg) {
						width: 280px;
					}
				}
			}

		}
	}

	// footer-copyright
	.footer-copyright {
		ul {
			display: flex;
			flex-direction: column;
			@extend .list;

			@include media-breakpoint-up(md) {
				flex-direction: row;
			}

			li {
				font-size: 16px;

				@include media-breakpoint-down(lg) {
					font-size: 14px;
				}

				@include media-breakpoint-up(md) {
					margin: 0 15px;
				}

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
