@import '../1_vendor/bootstrap-datepicker';

.timeslot_overview {
  margin: $timeslot-overview-margin;
  
  .timeslot-overview-row {
    @include make-row();
    justify-content: $timeslot-overview-row-justify_content;

    margin: $timeslot-overview-row-margin;
  }
}

.timeslot-category {
  @include make-col-ready();
  @include make-col(12);

  margin: $timeslot-category-margin;

  .timeslot-category-row {
    @include make-row();
    justify-content: $timeslot-category-row-justify_content;

    margin: $timeslot-category-row-margin;
  }

  .timeslot-category-content {
    @include make-col-ready();
    @include make-col(12);

    margin: $timeslot-category-content-margin;

    .timeslot-category-title {
      margin: $timeslot-category-title-margin;

      font-family: $timeslot-category-title-font_family;
      font-size: $timeslot-category-title-font_size;
      font-style: $timeslot-category-title-font_style;
      font-weight: $timeslot-category-title-font_weight;
      line-height: $timeslot-category-title-line_height;
      text-transform: $timeslot-category-title-text_transform;
      text-decoration: $timeslot-category-title-text_decoration;
      color: $timeslot-category-title-color;
    }
  }
}

.timeslot_highlight {
  margin: $timeslot-highlight-margin;

  .timeslot-highlight-row {
    @include make-row();
    justify-content: $timeslot-highlight-row-justify_content;

    margin: $timeslot-highlight-row-margin;
  }
}

.timeslot-datepicker-holder,
.timeslot-item-holder {
  @include make-col-ready();
  @include make-col($timeslot-item-col);

  margin: $timeslot-item-margin;

  @include media-breakpoint-up(sm) {
    @include make-col($timeslot-item-col_sm);
  }

  @include media-breakpoint-up(md) {
    @include make-col($timeslot-item-col_md);
  }

  @include media-breakpoint-up(lg) {
    @include make-col($timeslot-item-col_lg);
  }

  @include media-breakpoint-up(xl) {
    @include make-col($timeslot-item-col_xl);
  }
}

.timeslot-datepicker {
  height: 100%;
  padding: $timeslot-datepicker-padding;

  background: $timeslot-datepicker-background;
  border: $timeslot-datepicker-border;
  border-radius: $timeslot-datepicker-border_radius;
  box-shadow: $timeslot-datepicker-box_shadow;

  .datepicker {
    width: 100%;
    height: 100%;
    padding: $timeslot-datepicker-picker-padding;

    background: $timeslot-datepicker-picker-background;
    border: $timeslot-datepicker-picker-border;
    border-radius: $timeslot-datepicker-picker-border_radius;

    .datepicker-days,
    .datepicker-months,
    .datepicker-years,
    .datepicker-decades,
    .datepicker-centuries {
      height: 100%;
    }

    .table-condensed {
      width: 100%;
      height: 100%;
    }

    table {
      tr {
        td {
          &.day {
            &.active {
              background: $timeslot-datepicker-calendar-item-background--active;
    
              color: $timeslot-datepicker-calendar-item-color--active;
            }
          }
    
          span {
            &.active {
              background: $timeslot-datepicker-calendar-item-background--active;
    
              color: $timeslot-datepicker-calendar-item-color--active;
            }
          }
        }
      }
    }
  }
}

.timeslot-item {
  height: 100%;
  padding: $timeslot-item-padding;

  background: $timeslot-item-background;
  border: $timeslot-item-border;
  border-radius: $timeslot-item-border_radius;
  box-shadow: $timeslot-item-box_shadow;

  .timeslot-item-title {
    margin: $timeslot-item-title-margin;

    font-family: $timeslot-item-title-font_family;
    font-size: $timeslot-item-title-font_size;
    font-style: $timeslot-item-title-font_style;
    font-weight: $timeslot-item-title-font_weight;
    line-height: $timeslot-item-title-line_height;
    text-transform: $timeslot-item-title-text_transform;
    text-decoration: $timeslot-item-title-text_decoration;
    color: $timeslot-item-title-color;
  }

  .timeslot-item-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $timeslot-item-navigation-padding;

    background: $timeslot-item-navigation-background;

    .timeslot-item-navigation-duration {
      float: 1 1 auto;
      padding: 0 10px;
      
      text-align: center;
    }

    .timeslot-item-navigation-btn {
      @extend .btn;
      padding: $timeslot-item-navigation-btn-padding;

      flex: 0 0 auto;
      background-color: $timeslot-item-navigation-btn-background_color;
      border: $timeslot-item-navigation-btn-border;
      border-radius: $timeslot-item-navigation-btn-border_radius;
      box-shadow: $timeslot-item-navigation-btn-box_shadow;

      font-size: $timeslot-item-navigation-btn-font_size;
      font-weight: $timeslot-item-navigation-btn-font_weight;
      line-height: $timeslot-item-navigation-btn-line_height;
      color: $timeslot-item-navigation-btn-color;

      &:hover {
        background-color: $timeslot-item-navigation-btn-background_color--hover;
        border: $timeslot-item-navigation-btn-border--hover;

        color: $timeslot-item-navigation-btn-color--hover;
      }
    }
  }

  .timeslot-item-days {
    margin: $timeslot-item-days-margin;

    .timeslot-item-day {
      @include make-row();
      margin: $timeslot-item-day-margin;

      .timeslot-item-day-title-holder,
      .timeslot-item-day-times-holder {
        @include make-col($timeslot-item-day-holders-col);
        padding: $timeslot-item-day-holders-padding;

        @include media-breakpoint-up(sm) {
          @include make-col($timeslot-item-day-holders-col_sm);
        }
      
        @include media-breakpoint-up(md) {
          @include make-col($timeslot-item-day-holders-col_md);
        }
      
        @include media-breakpoint-up(lg) {
          @include make-col($timeslot-item-day-holders-col_lg);
        }
      
        @include media-breakpoint-up(xl) {
          @include make-col($timeslot-item-day-holders-col_xl);
        }
      }

      .timeslot-item-day-title {
        height: 100%;
        padding: $timeslot-item-day-title-padding;

        background: $timeslot-item-day-title-background;

        font-size: $timeslot-item-day-title-font_size;
        font-style: $timeslot-item-day-title-font_style;
        font-weight: $timeslot-item-day-title-font_weight;
        line-height: $timeslot-item-day-title-line_height;
        text-transform: $timeslot-item-day-title-text_transform;
        text-decoration: $timeslot-item-day-title-text_decoration;
        color: $timeslot-item-day-title-color;
      }
      
      .timeslot-item-day-times {
        height: 100%;
        padding: $timeslot-item-day-times-padding;

        background: $timeslot-item-day-times-background;

        font-size: $timeslot-item-day-times-font_size;
        font-style: $timeslot-item-day-times-font_style;
        font-weight: $timeslot-item-day-times-font_weight;
        line-height: $timeslot-item-day-times-line_height;
        text-transform: $timeslot-item-day-times-text_transform;
        text-decoration: $timeslot-item-day-times-text_decoration;
        color: $timeslot-item-day-times-color;

        .timeslot-item-day-times-list {
          margin: 0;
          padding: 0;

          list-style: none;

          &::before,
          &::after {
            display: none;
          }

          .timeslot-item-day-times-list-item {
            font-size: inherit;
            font-weight: inherit;
            text-transform: inherit;
            text-decoration: inherit;
            color: inherit;
          }
        }

        .timeslot-item-day-times-info {
          position: relative;

          .timeslot-item-day-times-info-popup {
            position: absolute;
            top: $timeslot-item-day-times-info-popup-top;
            right: $timeslot-item-day-times-info-popup-right;
            bottom: $timeslot-item-day-times-info-popup-bottom;
            left: $timeslot-item-day-times-info-popup-left;

            display: none;
            width: $timeslot-item-day-times-info-popup-width;
            margin: $timeslot-item-day-times-info-popup-margin;
            padding: $timeslot-item-day-times-info-popup-padding;

            background: $timeslot-item-day-times-info-popup-background;
            border: $timeslot-item-day-times-info-popup-border;
            border-radius: $timeslot-item-day-times-info-popup-border_radius;
            box-shadow: $timeslot-item-day-times-info-popup-box_shadow;

            font-size: $timeslot-item-day-times-info-popup-font_size;
            line-height: $timeslot-item-day-times-info-popup-line_height;
            color: $timeslot-item-day-times-info-popup-color;

            & > * {
              font-size: inherit;
              color: inherit;
            }

            :last-child {
              margin: 0;
            }

            &::before {
              position: absolute;
              top: $timeslot-item-day-times-info-arrow-top;
              right: $timeslot-item-day-times-info-arrow-right;
              bottom: $timeslot-item-day-times-info-arrow-bottom;
              left: $timeslot-item-day-times-info-arrow-left;

              width: 0;
              height: 0;
              margin: $timeslot-item-day-times-info-arrow-margin;

              border: $timeslot-item-day-times-info-arrow-border;
              border-top-color: $timeslot-item-day-times-info-arrow-border_top_color;
              border-right-color: $timeslot-item-day-times-info-arrow-border_right_color;
              border-bottom-color: $timeslot-item-day-times-info-arrow-border_bottom_color;
              border-left-color: $timeslot-item-day-times-info-arrow-border_left_color;

              content: '';
            }
          }

          &:hover {
            .timeslot-item-day-times-info-popup {
              display: block;
            }
          }
        }
      }
    }
  }
}