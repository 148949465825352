// body
& {
	@include media-breakpoint-up(xl) {
		font-size: 18px;
	}
}

// container
.container {
	@include media-breakpoint-up(sm) {
		padding: $container-padding_sm !important;
	}

	@media (min-width: 1400px) {
		max-width: 1260px;
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// custom-owl-nav
.custom-owl-nav {
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0;
	height: 54px;

	@include media-breakpoint-down(lg) {
		display: none;
	}

	.owl-prev {
		left: 0;

		&:hover {
			left: -3px;
		}
	}

	.owl-next {
		right: 0;

		&:hover {
			right: -3px;
		}
	}
}

// custom-owl-dots
.custom-owl-dots {
	margin: 30px 0;

	@include media-breakpoint-up(lg) {
		margin-left: 85px;
		margin-right: 85px;
	}

	.owl-dot {
		margin: 7px;

		span {
			position: relative;
			width: 20px;
			height: 20px;

			@include media-breakpoint-up(lg) {
				width: 40px;
				height: 40px;
			}

			margin: 0;
			border-radius: 100%;
			background: $white;
			border: none;
			transition: 0.5s;

			&::after {
				content: "";
				position: absolute;
				top: -5px;
				left: -5px;
				width: 30px;
				height: 30px;
				border-radius: 100%;
				border: 2px dotted $white;
				transition: 0.5s;

				@include media-breakpoint-up(lg) {
					top: -5px;
					left: -5px;
					width: 50px;
					height: 50px;
					border-width: 3px;
				}
			}
		}

		&:focus,
		&:hover,
		&.active {
			span {
				background: $primary;
				border: none;

				&::after {
					border-color: $primary;
				}
			}
		}
	}
}

// custom-contentblock
.custom-contentblock {
	min-height: auto;

	.card {
		width: 100%;
		height: auto !important;

		@include media-breakpoint-up(md) {
			flex-direction: row-reverse !important;
		}

		background: none;
		box-shadow: none;
		border-radius: 0;

		.card-body {
			@include media-breakpoint-up(md) {
				@include make-col(5);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(4);
			}

			background: transparent;
			padding: 0;
			justify-content: center;

			.card-caption {
				z-index: 1;
				display: flex;
				flex-direction: column;
				flex: 0;

				@include media-breakpoint-down(sm) {
					margin-top: 15px;
				}

				.card-title,
				.card-subtitle {
					margin-bottom: 0;
					color: $white;
					@include font-size($h1-font-size);
					line-height: 1;
					font-weight: 900;
					text-transform: none;
					word-wrap: normal;
					text-shadow: 4px 4px 0 $quaternary;

					@include media-breakpoint-up(xl) {
						text-shadow: 8px 8px 0 $quaternary;
						font-size: 80px;
					}
				}

				.card-title {
					margin-bottom: 15px;
				}

				.card-description {
					margin-top: 1rem;
					color: $white;

					@include media-breakpoint-up(xl) {
						font-size: 18px;
					}
					ul {
						@extend .custom-list;
						@include media-breakpoint-down(md) {
							li {
								padding-left: 0 !important;
								&::before {
									position: static !important;
									margin-right: 5px;
								}
							}
						}
					}
				}

				@include media-breakpoint-down(sm) {

					.card-title,
					.card-subtitle,
					.card-description {
						text-align: center;
					}
				}
			}

			.card-buttons {
				margin-top: 1rem;

				.card-btn {
					padding: 1.25rem 1.75rem;
				}

				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}
		}

		.card-image {
			background: transparent;

			@include media-breakpoint-up(md) {
				@include make-col(7);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(8);
			}

			@include media-breakpoint-up(xl) {
				@include make-col(8);
			}
		}
	}
}

&.main {
	.custom-contentblock {
		.card {
			.card-body {
				.card-caption {

					.card-subtitle,
					.card-title {
						@include media-breakpoint-up(xl) {
							min-width: 1000px;
						}
					}

					.card-description {
						order: 0;
						margin-top: 0;
						font-weight: 500;
					}
				}
			}
		}
	}
}

// full-section
.fullpage-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}
