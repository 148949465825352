&.location_accommodation_overview,
&.accommodation_category_overview,
&.accommodation_overview {
	.content {}
}

&.accommodation_arrangement_overview {
	.content {}
}

&.accommodation_search_book {
	.content {}
}

&.accommodation_category_detail,
&.accommodation_detail {
	.content {}

	.accommodation_detail {
		@include make-row();
		@extend .pb-5;

		.info {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pr-lg-5;
		}

		.accommodation-properties {
			display: flex;
			flex-wrap: wrap;

			margin: 0 0 10px 0;
			padding: 0;

			list-style: none;

			&__item { margin: 0 20px 0 0; }

			&__icon {
				margin: 0 10px 0 0;

				color: $primary;
			}

			&__label {
				color: $body-color;
			}
		}

		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pl-lg-5;
		}

		.tommy {
			@include make-col-ready();
			@include make-col(12);
		}
	}
}