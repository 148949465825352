// text-focus-in
.text-focus-in {
	animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes text-focus-in {
	0% {
		filter: blur(12px);
		opacity: 0;
	}

	100% {
		filter: blur(0);
		opacity: 1;
	}
}

// kenburns-bottom
.kenburns-bottom {
	animation: kenburns-bottom 7s ease-out both;
}

@keyframes kenburns-bottom {
	0% {
		transform: scale(1) translateY(0);
		transform-origin: 50% 84%;
	}

	100% {
		transform: scale(1.25) translateY(15px);
		transform-origin: bottom;
	}
}

// slide-in-fwd-top
.slide-in-fwd-top {
	animation: slide-in-fwd-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-fwd-top {
	0% {
		transform: translateZ(-1400px) translateY(-800px);
		opacity: 0;
	}

	100% {
		transform: translateZ(0) translateY(0);
		opacity: 1;
	}
}

// slide-out-elliptic-top-bck
.slide-out-elliptic-top-bck {
	animation: slide-out-elliptic-top-bck 0.7s ease-in both;
}

@keyframes slide-out-elliptic-top-bck {
	0% {
		transform: translateY(0) rotateX(0) scale(1);
		transform-origin: 50% 1400px;
		opacity: 1;
	}

	100% {
		transform: translateY(-600px) rotateX(-30deg) scale(0);
		transform-origin: 50% 100%;
		opacity: 1;
	}
}

// scale-up-center
.scale-up-center {
	animation: scale-up-center 10s ease-out backwards;
}

@keyframes scale-up-center {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.25);
	}
}
