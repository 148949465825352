&.blog_post_overview {
	.content {}
}

&.blog_post_detail {
	.content {}
		
	.blog_detail {
		@include make-row();

		.info {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pr-lg-5;
		}

		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}

			@extend .mb-4;
			@extend .pl-lg-5;
		}
	}
}