.form {
  /* form > form-error-icon
    ========================================================================== */
  .form-error-icon {
    @extend .d-none;
  }

  /* form > form-group
    ========================================================================== */
  .form-group {
    margin: $form-group-margin;
  }

  /* form > form-control-label
    ========================================================================== */
  .form-control-label {
    margin: $form-control-label-margin;

    font-family: $form-control-label-font_family;
    font-size: $form-control-label-font_size;
    font-style: $form-control-label-font_style;
    font-weight: $form-control-label-font_weight;
    line-height: $form-control-label-line_height;
    color: $form-control-label-color;
  }

  /* form > form-control
    ========================================================================== */
  .form-control {
    padding: $form-control-padding;
    background-color: $form-control-background_color;
    border: $form-control-border;
    border-radius: $form-control-border_radius;
    //box-shadow: $form-control-box_shadow;

    font-family: $form-control-font_family;
    font-size: $form-control-font_size;
    font-style: $form-control-font_style;
    font-weight: $form-control-font_weight;
    line-height: $form-control-line_height;
    color: $form-control-color;

    &.is-invalid {
      border: $form-control-border--invalid;
    }
  }

  .g-recaptcha {
    margin: $form-group-margin;
  }

  .reCAPTCHA-text {
    margin: $form-reCAPTCHA-margin;

    font-size: $form-reCAPTCHA-font_size;
    font-style: $form-reCAPTCHA-font_style;
    font-weight: $form-reCAPTCHA-font_weight;
    line-height: $form-reCAPTCHA-line_height;
    color: $form-reCAPTCHA-color;
  }
}

/* form > bg-dark
   ========================================================================== */
.bg-dark {
  .form {
    .form-control-label {
      color: $form-control-label-color--dark;
    }

    .form-control {
      background-color: $form-control-background_color--dark;
      border: $form-control-border--dark;
      color: $form-control-color--dark;

      &.is-invalid {
        border: $form-control-border--invalid--dark;
      }
    }

    .reCAPTCHA-text {
      color: $form-reCAPTCHA-color--dark;
    }
  }
}