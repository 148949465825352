// btn
.btn:not(.timeslot-item-navigation-btn) {
	padding: 0.75rem 0.75rem;
	border-radius: 5px;
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	@include media-breakpoint-up(xl) {
		padding: 1.25rem 1.75rem;
	}
	&:hover {
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
	}

	// btn-primary
	&.btn-primary {
		background-color: $tertiary;
		border-color: $tertiary;
		color: $white;
		&:hover {
			background-color: darken($tertiary, 15%);
			border-color: darken($tertiary, 15%);
		}
	}
}

// btn-white
.btn-white {
	color: $red;
	&:hover {
		background: darken($white, 15%);
		border-color: darken($white, 15%);
		color: $red;
	}
}

// btn-back
.btn-back {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&::before {
		display: inline-block;
	}
}

// owl-btn
.owl-btn {
	&:hover {
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35) !important;
	}
}

// btn-toggle-mini-sab
.btn-toggle-mini-sab {
	@extend .btn;
	@extend .btn-primary;
	width: 100%;
}
