// menu-overlay
.menu-overlay {
	@include media-breakpoint-up(xl) {
		padding: 0 15px;
	}
	.menu {
		@include media-breakpoint-down(lg) {
			padding-bottom: 15px;
			.navbar-nav {
				.nav-item {
					font-size: 16px;
					font-weight: 700;
					> .nav-link {
						color: $white;
						i {
							width: 20px;
						}
						&:hover {
							color: $secondary;
						}
					}
					&.active {
						> .nav-link {
							color: $secondary;
						}
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						background-color: transparent;
						.nav-item {
							font-size: 15px;
							line-height: 1.2;
						}
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			.navbar-nav {
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: center;
				padding-bottom: 25px;
				> .nav-item {
					margin: 0 25px;
					font-size: 16px;
					font-weight: 700;
					> .nav-link {
						color: $white;
						i {
							width: 20px;
						}
						&:hover {
							color: $secondary;
						}
						&.dropdown-toggle {
							color: $white;
							&::after {
								display: none;
							}
						}
					}
					&.active {
						> .nav-link {
							color: $secondary;
							&.dropdown-toggle {
								color: $white;
							}
						}
					}
				}
				.nav-item {
					.dropdown-menu {
						&.show {
							position: static !important;
							transform: none !important;
							will-change: unset !important;
						}
						display: block;
						transform: none;
						background: transparent;
						border: none;
						margin: 0 0 0 25px;
						padding: 0;
						.nav-item {
							font-size: 15px;
							line-height: normal;
							.nav-link {
								padding-top: 0.25rem;
								padding-bottom: 0.25rem;
								color: $white;
								&:hover {
									color: $secondary;
								}
							}
							&.active {
								.nav-link {
									color: $secondary;
								}
							}
						}
					}
				}
			}
		}
	}
}
