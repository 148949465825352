// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

@mixin defineColorHSLA($color-name, $value) {
	$hue: hue($value);
	$saturation: saturation($value);
	$lightness: lightness($value);
	$alpha: alpha($value);

	#{$color-name}: unquote("hsla(#{$hue}, #{$saturation}, #{$lightness}, #{$alpha})");
	#{$color-name}-hsla: $hue, $saturation, $lightness, $alpha;
	#{$color-name}-h: $hue;
	#{$color-name}-s: $saturation;
	#{$color-name}-l: $lightness;
	#{$color-name}-a: $alpha;
}

// css3 root vars setup
:root {
	@include defineColorHSLA(--w3-primary-color, #003b88); /* Oostappen Blauw */
	@include defineColorHSLA(--w3-secondary-color, #008fdb); /* Oostappen Lichtblauw */
	@include defineColorHSLA(--w3-tertiary-color, #d80012); /* Oostappen Rood */
	@include defineColorHSLA(--w3-quaternary-color, #000000); /* Shadows */
}

// colors
$red: #d80012;
$yellow: #f2d808;
$green: #3aaa37;

$white: #fff;
$black: #000;

// theme-colors
$primary: var(--w3-primary-color);
$secondary: var(--w3-secondary-color);
$tertiary: var(--w3-tertiary-color);
$quaternary: var(--w3-quaternary-color);

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $secondary;
$link-decoration: underline;

// typography
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap");

$font-family-primary: "Exo", sans-serif;
$font-family-secondary: "Rubik", sans-serif;

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base * 1.125; // 18px

$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-color: $primary;

// contextual
$light: $secondary;
$dark: $primary;
$text-light: $white;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);
